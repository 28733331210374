import {FragmentsGroup} from 'bim-fragment';
import {
  Component,
  Components,
  Disposable,
  Event,
  FragmentIdMap,
  FragmentManager,
  IfcCategoryMap,
  IfcPropertiesUtils,
  ToolComponent,
} from 'openbim-components';

// TODO: Clean up and document

export interface Classification {
  [system: string]: {
    [className: string]: FragmentIdMap;
  };
}

export class FragmentClassifier extends Component<Classification> implements Disposable {
  static readonly uuid = 'e25a7f3c-46c4-4a14-9d3d-5115f24ebeb7' as const;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _groupSystems: Classification = {};

  /** {@link Component.enabled} */
  enabled = true;

  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  constructor(components: Components) {
    super(components);
    components.tools.add(FragmentClassifier.uuid, this);
    const fragmentManager = components.tools.get(FragmentManager);

    fragmentManager.onFragmentsDisposed.add(this.onFragmentsDisposed);
  }

  /** {@link Component.get} */
  get(): Classification {
    return this._groupSystems;
  }

  async dispose() {
    this._groupSystems = {};
    const fragmentManager = this.components.tools.get(FragmentManager);

    fragmentManager.onFragmentsDisposed.remove(this.onFragmentsDisposed);
    await this.onDisposed.trigger(FragmentClassifier.uuid);
    this.onDisposed.reset();
  }

  remove(guid: string) {
    for (const systemName in this._groupSystems) {
      const system = this._groupSystems[systemName];

      for (const groupName in system) {
        const group = system[groupName];

        delete group[guid];
      }
    }
  }

  // eslint-disable-next-line max-statements
  find(filter?: {[name: string]: string[]}) {
    const fragments = this.components.tools.get(FragmentManager);

    if (!filter) {
      const result: FragmentIdMap = {};
      const fragList = fragments.list;

      for (const id in fragList) {
        const fragment = fragList[id];

        result[id] = new Set(fragment.ids);
      }

      return result;
    }

    // There must be as many matches as conditions.
    // E.g.: if the filter is "floor 1 and category wall",
    // this gets the items with 2 matches (1 match per condition)
    const filterCount = Object.keys(filter).length;

    const models: {[fragmentGuid: string]: Map<number, number>} = {};

    for (const name in filter) {
      const values = filter[name];

      if (!this._groupSystems[name]) {
        console.warn(`Classification ${name} does not exist.`);
        continue;
      }

      for (const value of values) {
        const found = this._groupSystems[name][value];

        if (found) {
          for (const guid in found) {
            if (!models[guid]) {
              models[guid] = new Map();
            }

            for (const id of found[guid]) {
              const matchCount = models[guid].get(id);

              if (matchCount === undefined) {
                models[guid].set(id, 1);
              } else {
                models[guid].set(id, matchCount + 1);
              }
            }
          }
        }
      }
    }

    const result: FragmentIdMap = {};

    for (const guid in models) {
      const model = models[guid];

      for (const [id, numberOfMatches] of model) {
        if (numberOfMatches === undefined) {
          throw new Error('Malformed fragments map!');
        }

        if (numberOfMatches === filterCount) {
          if (!result[guid]) {
            result[guid] = new Set();
          }

          result[guid].add(id);
        }
      }
    }

    return result;
  }

  byModel(modelID: string, group: FragmentsGroup) {
    if (!this._groupSystems.model) {
      this._groupSystems.model = {};
    }

    const modelsClassification = this._groupSystems.model;

    if (!modelsClassification[modelID]) {
      modelsClassification[modelID] = {};
    }

    const currentModel = modelsClassification[modelID];

    for (const [expressID, data] of group.data) {
      const keys = data[0];

      for (const key of keys) {
        const fragID = group.keyFragments.get(key);

        if (!fragID) {
          continue;
        }

        if (!currentModel[fragID]) {
          currentModel[fragID] = new Set<number>();
        }

        currentModel[fragID].add(expressID);
      }
    }
  }

  async byPredefinedType(group: FragmentsGroup) {
    if (!this._groupSystems.predefinedTypes) {
      this._groupSystems.predefinedTypes = {};
    }

    const currentTypes = this._groupSystems.predefinedTypes;

    const ids = group.getAllPropertiesIDs();

    for (const id of ids) {
      const entity = await group.getProperties(id);

      if (!entity) {
        continue;
      }

      const predefinedType = String(entity.PredefinedType?.value).toUpperCase();

      if (!currentTypes[predefinedType]) {
        currentTypes[predefinedType] = {};
      }

      const currentType = currentTypes[predefinedType];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [_expressID, data] of group.data) {
        const keys = data[0];

        for (const key of keys) {
          const fragmentID = group.keyFragments.get(key);

          if (!fragmentID) {
            throw new Error('Fragment ID not found!');
          }

          if (!currentType[fragmentID]) {
            currentType[fragmentID] = new Set<number>();
          }

          const currentFragment = currentType[fragmentID];

          currentFragment.add(entity.expressID);
        }
      }
    }
  }

  byEntity(group: FragmentsGroup) {
    if (!this._groupSystems.entities) {
      this._groupSystems.entities = {};
    }

    for (const [expressID, data] of group.data) {
      const rels = data[1];
      const type = rels[1];
      const entity = IfcCategoryMap[type];

      this.saveItem(group, 'entities', entity, expressID);
    }
  }

  async byStorey(group: FragmentsGroup) {
    /**
     * key - storeyId
     * value - storeyName
     */
    const storeyNamesMap = new Map<number, string>();

    for (const [expressID, [, [storeyId]]] of group.data) {
      /**
       * group.data – Map<expressID, [keys, rels]>
       *
       * keys = fragmentKeys to which this asset belongs
       * rels = [floorId, categoryId]
       */

      let storeyName = 'Без номера';

      if (!storeyNamesMap.has(storeyId)) {
        const props = await group.getProperties(storeyId);
        const storeyNameNumber = parseInt(props?.Name?.value, 10);

        if (Number.isNaN(storeyNameNumber)) {
          console.warn(
            `В IFC-модели этаж не имеет имени или содержит в нём символы кроме цифр (${props?.Name?.value})`,
          );
          storeyName = storeyId.toString();
        } else {
          storeyName = storeyNameNumber.toString();
        }

        storeyNamesMap.set(storeyId, storeyName);
      }

      this.saveItem(group, 'storeys', storeyNamesMap.get(storeyId), expressID);
    }
  }

  async byIfcRel(group: FragmentsGroup, ifcRel: number, systemName: string) {
    if (!IfcPropertiesUtils.isRel(ifcRel)) {
      return;
    }

    await IfcPropertiesUtils.getRelationMap(group, ifcRel, async (relatingID, relatedIDs) => {
      const {name: relatingName} = await IfcPropertiesUtils.getEntityName(group, relatingID);

      for (const expressID of relatedIDs) {
        this.saveItem(group, systemName, relatingName ?? 'NO REL NAME', expressID);
      }
    });
  }

  private saveItem(group: FragmentsGroup, systemName: string, className: string, expressID: number) {
    if (!this._groupSystems[systemName]) {
      this._groupSystems[systemName] = {};
    }

    const keys = group.data.get(expressID);

    if (!keys) {
      return;
    }

    for (const key of keys[0]) {
      const fragmentID = group.keyFragments.get(key);

      if (fragmentID) {
        const system = this._groupSystems[systemName];

        if (!system[className]) {
          system[className] = {};
        }

        if (!system[className][fragmentID]) {
          system[className][fragmentID] = new Set<number>();
        }

        system[className][fragmentID].add(expressID);
      }
    }
  }

  private readonly onFragmentsDisposed = (data: {groupID: string; fragmentIDs: string[]}) => {
    const {groupID, fragmentIDs} = data;

    for (const systemName in this._groupSystems) {
      const system = this._groupSystems[systemName];
      const groupNames = Object.keys(system);

      if (groupNames.includes(groupID)) {
        delete system[groupID];

        if (Object.values(system).length === 0) {
          delete this._groupSystems[systemName];
        }
      } else {
        for (const groupName of groupNames) {
          const group = system[groupName];

          for (const fragmentID of fragmentIDs) {
            delete group[fragmentID];
          }

          if (Object.values(group).length === 0) {
            delete system[groupName];
          }
        }
      }
    }
  };
}

ToolComponent.libraryUUIDs.add(FragmentClassifier.uuid);
