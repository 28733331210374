<div class="header">
  <div class="title header__title">{{ error.title }}</div>
  <div class="badges">
    <tui-tag
      class="error__code"
      [size]="'m'"
      [value]="error.status + '\u00A0' + error.type"
      [status]="'error'"
    ></tui-tag>
    <tui-tag
      class="error__trace-id"
      [size]="'m'"
      [value]="'TraceId: ' + error.traceId"
      [hoverable]="true"
      [status]="'warning'"
      (click)="copyToClipboard(error.traceId)"
    ></tui-tag>
  </div>
</div>
<div class="content">
  <tui-scrollbar>
    @if (error.message !== undefined && error.message !== null) {
      <div class="error-message">
        <div class="error-message__label">Ошибка подсистемы управления:</div>
        <div class="error-message__value">
          {{ error.message | textTrim }}
        </div>
      </div>
    }

    @if (error.MessagesChain !== undefined && error.MessagesChain !== null) {
      <div class="error-message">
        <div class="error-message__label">Ошибка из подсистемы:</div>
        <div class="error-message__value log">
          {{ error.MessagesChain | textTrim }}
        </div>
      </div>
    }
  </tui-scrollbar>
</div>

<div class="footer">
  <div class="actions">
    <button
      tuiButton
      [appearance]="'secondary'"
      [icon]="'tuiIconCopy'"
      [size]="'m'"
      (click)="copyToClipboard(error.detail)"
    >
      Скопировать детальный лог
    </button>
  </div>
</div>
