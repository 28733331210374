import {Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngxs/router-plugin';

export interface RouterStateParams {
  url: string;
  params: Params;
  pathParams: Params;
  queryParams: Params;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: {queryParams},
    } = routerState;

    let {root: route} = routerState;
    let pathParams: Params = {...route.params};

    while (route.firstChild) {
      route = route.firstChild;
      pathParams = {...pathParams, ...route.params};
    }

    const {params} = route;

    return {url, params, queryParams, pathParams};
  }
}
