export interface ElevatorsDto {
  readonly id: string;
  readonly name: string;
  readonly geometry: {
    readonly profile: Array<Record<string, any>>; // [{}];
    readonly innerProfiles: Array<Array<Record<string, any>>>; // [[{}]];
    readonly height: number;
  };
  readonly liftingCapacity: number;
  readonly liftingHeight: number;
  readonly stopPointsCount: number;
  readonly model: string;
  readonly purpose: string;
  readonly doubleDoor: boolean;
  readonly fireproofLimit: string;
  readonly manufacturer: string;
  readonly speed: number;
}
