<div class="header">
  <div class="title">Просмотр JSON</div>
</div>
<div class="content">
  <tui-scrollbar>
    <code language="json" [highlight]="jsonString"></code>
  </tui-scrollbar>
</div>

<div class="footer">
  <div class="actions">
    <button tuiButton [appearance]="'secondary'" [icon]="'tuiIconCopy'" [size]="'m'" (click)="copyToClipboard()">
      Скопировать
    </button>
  </div>
</div>
