<ng-container *tuiLet="subsystemShellRoot$ | async as root">
  <a
    *ngIf="backLink$ | async as link"
    automation-id="breadcrumbs_backward"
    class="icon-link"
    [routerLink]="link"
    [relativeTo]="root"
  >
    <tui-svg src="tuiIconArrowLeftLarge" />
  </a>

  <a
    *ngIf="mapLinkData$ | async as mapLinkData"
    automation-id="breadcrumbs_map-link"
    class="icon-link"
    [routerLink]="mapLinkData.link"
    [queryParams]="mapLinkData.params"
    [relativeTo]="root"
  >
    <tui-svg src="tuiIconMapLarge" />
  </a>

  <div class="caption">
    <div automation-id="breadcrumbs_page-title" class="title">{{ title }}</div>
    <project-breadcrumbs class="project-breadcrumbs" />
  </div>

  <tui-scrollbar class="subsystem-breadcrumbs-wrapper">
    <div class="subsystem-breadcrumbs">
      <subsystem-breadcrumbs-link
        *ngFor="let item of breadcrumbsData$ | async; trackBy: trackBySubsystemName"
        [breadcrumbsLink]="item"
        [relativeTo]="root"
      />
    </div>
  </tui-scrollbar>

  <div *ngIf="rightTemplate$ | async as rightTemplate" class="right-content">
    <ng-template *ngTemplateOutlet="rightTemplate"></ng-template>
  </div>
</ng-container>
