import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../../enums';
import {UserInfoDto} from '../user-info.dto';

export interface GenerationTaskListItemDto {
  readonly id: string;
  readonly number: string;

  readonly description: string;
  readonly status: GenerationTaskStatusEnum;
  readonly subsystem: GenerationTaskSubsystemType;
  readonly projectId: string;
  readonly parentId: string;

  readonly author: UserInfoDto;
  readonly lastEditor: UserInfoDto;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly createdAt: string;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly lastModifiedAt: string;
}
