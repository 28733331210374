import {FormControl, FormGroup} from '@angular/forms';

export type ProjectRotationFormControls = {
  readonly rotationAngle: FormControl<number | null>;
};

export type ProjectRotationFormGroup = FormGroup<ProjectRotationFormControls>;

export type ProjectRotationFormValue = {
  readonly rotationAngle: number | null;
};
