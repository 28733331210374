/**
 * @alias CornerHallwaySettings
 */

export type LayoutCornerHallwaySettingsDto = {
  axisStepSize: number;
  insertOffset: number;
  defaultBorderOffset: number;
  canBeExtended: boolean;
};
