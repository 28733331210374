import {EomLightingDto, EomSwitchDto} from '../../../types';
import {EomBoxesDto} from './eom-boxes.dto';
import {EomEngineeringSubZoneDto} from './eom-engineering-sub-zone.dto';
import {EomEquipmentDto} from './eom-equipment.dto';
import {EomReceptacleDto} from './eom-receptacle.dto';

export interface EomConfigDto {
  readonly receptacle: EomReceptacleDto;
  readonly switch: EomSwitchDto;
  readonly lighting: EomLightingDto;
  readonly equipment: EomEquipmentDto;
  readonly boxes: EomBoxesDto;
  readonly engineeringSubZone: EomEngineeringSubZoneDto;
}
