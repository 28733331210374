import {LayoutAxisSizeDto} from './layout-axis-size.dto';
import {LayoutCellTemplateDto} from './layout-cell-template.dto';
/**
 * @alias SlotTemplateParameter
 */
export type LayoutSlotTemplateParameterDto = {
  id: number;
  apartmentTypeIds: number[];
  facade: number[];
  reflection: boolean;
  cells: LayoutCellTemplateDto[];
  axisSizes: LayoutAxisSizeDto[];
  name: string;
};
