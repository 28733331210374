/**
 * Пока экспериментально. Нужен дополнительный чеккер, который будет способен
 * отличать остальный бодики ошибок от данного
 */
export interface GenerationSubsystemError {
  /**
   * @example 'Bad Request'
   */
  readonly type: string;

  /**
   * Человекочитаемый текст об ошибке без каких-либо подробностей. Для заголовка
   * нотификейшена слишком длинный.
   *
   * @example 'Ошибка коммуникации с модулем АР - КЗ'
   */
  readonly title: string | null;

  /**
   * Код ошибки взаимодействия с подсистемой
   */
  readonly status: number;

  /**
   * Полный текст ошибки со стек-трейсом
   */
  readonly detail: string;
  /**
   * Уникальный код ошибки. Видимо для поиска в логах
   */
  readonly uniqueCode: number;

  /**
   * Краткий текст ошибки с проставленным в начале uniqueCode
   *
   * @example '937050: Ошибка запроса к модулю АР - КЗ, возможно не совпадают контракты'
   */
  readonly message: string;

  /**
   * Код для трейсинга
   *
   * @example '0HN6CT16V044H:00000003'
   */
  traceId: string;

  /**
   * Текст ошибки пришедший от подсистемы
   *
   * @example 'Ошибка запроса: ошибка валидации входных данных\n\nStatus: 400\nResponse: \n{"type":"https://tools.ietf.org/html/rfc9110#section-15.5.1","title":"One or more validation errors occurred.","status":400,"errors":{"ApartmentTopography":["Пересекаются диапазоны площадей для типов 1К(L) и 1К(M))"]},"traceId":"00-4540f13d2196954516df411d7b57c2ff-fa8022946eaa1453-01"}'
   */
  MessagesChain: string;

  /**
   * @todo на текущий момент кроме `null` ничего не приходило
   */
  user: null;

  /**
   * @todo не ясно будет ли тут целиком задание, обычно приходит `'{}'`
   */
  data: string | null;
}
