import {EntryFormValue} from '@portfolio/util';
import {PortfolioDto} from '@projects-api-v2/util';

export class Create {
  static readonly type = '[Portfolio Entry Form] Create';
  constructor(public formValue: EntryFormValue) {}
}

export class CreateSuccess {
  static readonly type = '[Portfolio Entry Form] Create Success';
  constructor(public apiResponse: string) {}
}

export class CreateFailure {
  static readonly type = '[Portfolio Entry Form] Create Failure';
  constructor(public error: string) {}
}

export class Update {
  static readonly type = '[Portfolio Entry Form] Update';
  constructor(
    public groupId: string,
    public formValue: EntryFormValue,
  ) {}
}

export class UpdateSuccess {
  static readonly type = '[Portfolio Entry Form] Update Success';
  constructor(public apiResponse: PortfolioDto) {}
}

export class UpdateFailure {
  static readonly type = '[Portfolio Entry Form] Update Failure';
  constructor(public error: string) {}
}

export class UploadImage {
  static readonly type = '[Portfolio Entry Form] Upload Image';
  constructor(public file: File) {}
}

export class UploadImageSuccess {
  static readonly type = '[Portfolio Entry Form] Upload Image Success';
  constructor(
    public apiResponse: string,
    public file: File,
  ) {}
}

export class UploadImageFailure {
  static readonly type = '[Portfolio Entry Form] Upload Image Failure';
  constructor(
    public error: string,
    public file: File,
  ) {}
}
