import {EomEquipmentWaterLeakSensorDto} from '../../../types';

export interface EomEquipmentDto {
  readonly waterLeakSensor: EomEquipmentWaterLeakSensorDto;
  readonly wiFiRouter: boolean;
  readonly waterLeakController: boolean;
  readonly doorBellButton: boolean;
  readonly unitLowVoltagePanel: boolean;
  readonly unitLoadCentrePanel: boolean;
  readonly additionalGroundingBar: boolean;
}
