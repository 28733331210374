import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {UpdateSuccess} from '@portfolio/data-access/entry-form';
import {DeleteEntrySuccess} from '@portfolio/data-access/entry-view';
import {DeleteListEntrySuccess} from '@portfolio/data-access/list-view';
import {PortfolioApiService} from '@projects-api-v2/data-access';
import {PortfolioDto} from '@projects-api-v2/util';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {GetData, GetDataFailure, GetDataSuccess, InitView} from './actions';

export interface State {
  portfolioId: string | null;

  data: PortfolioDto | null;
  error: string | null;
  loading: boolean;
}

export const defaultState: State = {
  portfolioId: null,

  data: null,
  error: null,
  loading: false,
};

@Injectable()
@State<State>({
  name: 'portfolioEntryViewShell',
  defaults: defaultState,
})
export class PortfolioEntryViewShellState {
  private readonly portfolioApiService = inject(PortfolioApiService);

  @Selector()
  static data(state: State) {
    return state.data;
  }

  @Selector()
  static loading(state: State) {
    return state.loading;
  }

  @Selector()
  static error(state: State) {
    return state.error;
  }

  @Action(InitView)
  initView(ctx: StateContext<State>, payload: InitView) {
    const state = ctx.getState();

    /**
     * Игнорируем загрузку при указании id === 'unknown'
     *
     * Данный id будет использоваться для случаев где при неизвестном
     * портфеле и его id треубется открывать страницы идущие за ним
     * по роутингу.
     *
     * Хлебные крошки это учитывают и пишут вместо наименования слово "Портфель"
     */
    if (payload.portfolioId === 'unknown') {
      return EMPTY;
    }

    if ((state.data === null && state.loading !== true) || state.data.id !== payload.portfolioId) {
      ctx.patchState({...defaultState, portfolioId: payload.portfolioId});

      return ctx.dispatch(new GetData());
    }

    return EMPTY;
  }

  @Action([DeleteListEntrySuccess, DeleteEntrySuccess])
  deleteEntrySuccess(ctx: StateContext<State>) {
    ctx.setState(defaultState);
  }

  @Action([UpdateSuccess])
  entryFormApiActionSuccess(ctx: StateContext<State>) {
    ctx.setState({
      ...defaultState,
      portfolioId: ctx.getState().portfolioId,
    });
    ctx.dispatch(new GetData());
  }

  @Action(GetData)
  getData(ctx: StateContext<State>) {
    const state = ctx.getState();

    ctx.patchState({loading: true});

    return this.portfolioApiService.getById(state.portfolioId).pipe(
      switchMap(apiResponse => ctx.dispatch(new GetDataSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new GetDataFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new GetDataFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(GetDataSuccess)
  getDataSuccess(ctx: StateContext<State>, payload: GetDataSuccess) {
    ctx.patchState({
      data: payload.apiResponse,
      loading: false,
    });
  }

  @Action(GetDataFailure)
  getDataFailure(ctx: StateContext<State>, payload: GetDataFailure) {
    ctx.patchState({
      error: payload.error,
      loading: false,
    });
  }
}
