import {inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {EMPTY} from 'rxjs';

import {InitView} from './actions';

export interface State {
  rootRoute: ActivatedRoute | null;
}

export const defaultState: State = {
  rootRoute: null,
};

@Injectable()
@State<State>({
  name: 'subsystemShell',
  defaults: defaultState,
})
export class SubsystemShellState {
  private readonly store = inject(Store);

  @Selector()
  static rootRoute(state: State) {
    return state.rootRoute;
  }

  @Action(InitView)
  initView(ctx: StateContext<State>, payload: InitView) {
    ctx.patchState({rootRoute: payload.activatedRoute});

    return EMPTY;
  }
}
