import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {API_URL_PREFIX, EomConfigDto} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of} from 'rxjs';

import {EomResourceFileType} from '../util/types';

@Injectable({providedIn: 'root'})
export class EomApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(id: string, fileType: EomResourceFileType): Observable<Blob> {
    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Eom/${id}/diagnostics/resource`, {
      params: {fileType},
      responseType: 'blob',
    });
  }

  getSettings(): Observable<EomConfigDto> {
    const mockData = {
      receptacle: {
        window: {
          windowBlinds: true,
        },
        kitchenEquipment: {
          fridge: true,
          kitchenModule: true,
          extractor: true,
          electricStove: true,
          stove: true,
          dishWasher: true,
        },
        furniture: {
          doubleSizeBed: true,
          singleSizeBed: true,
          workTable: true,
          sofa: true,
          dinningTable: true,
        },
        equipment: {
          camera: true,
          unitPanel: true,
          tv: true,
        },
        waterCloset: {
          placementType: 'all',
        },
      },
      switch: {
        livingRoom: true,
        kitchen: true,
        diningRoom: true,
        hallWay: true,
        hall: true,
        dressingRoom: true,
        waterCloset: true,
        bedRoom: true,
        anyRoom: true,
      },
      lighting: {
        bedRoom: true,
        livingRoom: true,
        kitchen: true,
        diningRoom: true,
        hallWay: true,
        hall: true,
        waterCloset: true,
        restAndBathRoom: 'fourLights',
        cabinet: true,
        laundryRoom: true,
        dressingRoom: true,
      },
      equipment: {
        waterLeakSensor: {
          bathRoom: 'underSink',
          kitchen: 'underAllEquipment',
        },
        wiFiRouter: true,
        waterLeakController: true,
        doorBellButton: true,
        unitLowVoltagePanel: true,
        unitLoadCentrePanel: true,
        additionalGroundingBar: true,
      },
      boxes: {
        electrical: {
          waterLeakController: true,
          receptacle: true,
          switch: true,
          doorBellButton: true,
          wiredRadioReceptacle: true,
        },
        terminal: {
          airConditioner: true,
          lightingSwitch: true,
          receptacle: true,
        },
        junction: {
          hallAndHallWay: true,
          bathroomMirror: true,
        },
      },
      engineeringSubZone: {
        fireCabinetAndManifoldEngineeringSubZoneOffset: 1000,
        plumbingEngineeringSubZoneOffset: 100,
        hvacEngineeringSubZoneOffset: 100,
      },
    };

    return of(mockData);
  }
}
