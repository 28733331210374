import {GenerationTaskDataCatalogReferenceDto} from './generation-task-data-catalog-reference.dto';
import {GenerationTaskDataHardcodeReferenceDto} from './generation-task-data-hardcode-reference.dto';

/**
 * Интерфейс получаемый из JSON-объекта, хранимого
 * в БД в виде строки для каждого задания.
 */
export interface GenerationTaskDataDto<D, H extends GenerationTaskDataHardcodeReferenceDto = {}> {
  /**
   * Основные свойства задания для соответствующей подсистемы.
   */
  readonly generationData: D | null;
  /**
   * В себе должен содержать захардкоженные свойства из задания. Будут
   * подставлены в задание как расширение основной модели, поэтому заполнять
   * нужно в соответствии с **временным** интерфейсом {@link GenerationTaskDataHardcodeReferenceDto}
   */
  readonly referenceDataHardcoded?: H | null;
  /**
   * Объект информации о прилинкованном справочнике. {@link GenerationTaskDataCatalogReferenceDto}
   */
  readonly referenceData?: GenerationTaskDataCatalogReferenceDto | null;

  /**
   * Бэк хранит наш объект в базе как строку, поэтому при
   * получении его обратно нужно дополнительно сериализовать данные
   */
  readonly clientData?: string;
}
