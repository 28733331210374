import {SharedWrapWallShiftScenarioType} from '../../../types';

export interface ShareWrapWallCreationSystemSettings {
  barrierProfileTolerance?: number;
  distanceToStiffeningCoreLimit?: number;
  maxOverhangFromSharedWrap?: number;
  maxShiftDistance?: number;
  minDistanceFromAdditionalWall?: number;
  minDistanceFromDoor?: number;
  sharedWrapWallShiftScenario?: SharedWrapWallShiftScenarioType;
}
