import {ApartmentTopographyParameterDto, FloorRangeInsolationParameterDto} from '@layout-api/util';

import {LayoutAddressDto} from './layout-address.dto';
import {LayoutApartmentTypeParameterDto} from './layout-apartment-type-parameter.dto';
import {LayoutGeographicCoordinatesDto} from './layout-geographic-coordinates.dto';
import {LayoutPresetParameterDto} from './layout-preset-parameter.dto';
import {LayoutRootConfigurationDto} from './layout-root-configuration.dto';
import {LayoutSlotTemplateParameterDto} from './layout-slot-template-parameter.dto';

/**
 * @alias TaskOptionsV1
 */
export type LayoutTaskOptionsDto = {
  sectionType?: number;
  storeyCount: number;
  typicalStoreyHeight: number;
  firstStoreyHeight: number;
  length: number;
  width: number;
  moduleSize: number;
  north: number;
  siteAddress: LayoutAddressDto;
  siteCoordinates: LayoutGeographicCoordinatesDto;
  name?: string;
  address?: string;
  floorInsolation: FloorRangeInsolationParameterDto[];
  apartmentTopography: ApartmentTopographyParameterDto[];
  slots?: LayoutSlotTemplateParameterDto[];
  apartmentTypes?: LayoutApartmentTypeParameterDto[];
  slotLibraryVersion?: string;
  configuration?: LayoutRootConfigurationDto;
  preset?: LayoutPresetParameterDto;
  /**
   * Отладочный параметр для снятия ограничений по общим размерам здания
   * для робота. По-умолчанию true – размеры здания ограничены.
   */
  fixedAxis: boolean;
  siteId?: string;
  buildingId?: string;
};
