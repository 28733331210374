import {Provider} from '@angular/core';
import {TUI_SVG_DEFAULT_OPTIONS, TUI_SVG_OPTIONS} from '@taiga-ui/core';

export const CUSTOM_ICONS_PROVIDER: Provider = {
  provide: TUI_SVG_OPTIONS,
  useValue: {...TUI_SVG_DEFAULT_OPTIONS, path: iconsCustomPath},
};

function iconsCustomPath(name: string): string {
  if (name.startsWith('tuiIcon') || name.startsWith('customIcon')) {
    return `assets/icons/${name}.svg#${name}`;
  }

  return name;
}
