import {ApartmentFormat, ApartmentRoomCount} from '@layout-api/util';

/**
 * @alias ApartmentTypeParameter
 */
export type LayoutApartmentTypeParameterDto = {
  id: number;
  roomCount: ApartmentRoomCount;
  format: ApartmentFormat;
  areaCoefficient: number;
};
