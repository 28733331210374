export type EomLightingDto = {
  readonly bedRoom: boolean;
  readonly livingRoom: boolean;
  readonly kitchen: boolean;
  readonly diningRoom: boolean;
  readonly hallWay: boolean;
  readonly hall: boolean;
  readonly waterCloset: boolean;
  readonly restAndBathRoom: string;
  readonly cabinet: boolean;
  readonly laundryRoom: boolean;
  readonly dressingRoom: boolean;
};
