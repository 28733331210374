import {PortfolioDto} from '@projects-api-v2/util';

export class InitView {
  static readonly type = '[Portfolio Entry View Shell] Init View';
  constructor(public portfolioId: string) {}
}

export class GetData {
  static readonly type = '[Portfolio Entry View Shell] Get Data';
}

export class GetDataSuccess {
  static readonly type = '[Portfolio Entry View Shell] Get Data Success';
  constructor(public apiResponse: PortfolioDto) {}
}

export class GetDataFailure {
  static readonly type = '[Portfolio Entry View Shell] Get Data Failure';
  constructor(public error: string) {}
}
