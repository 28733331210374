import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../enums';

export type GenerationTasksGetParams = {
  id?: string;
  number?: string;
  status?: GenerationTaskStatusEnum;
  subsystem?: GenerationTaskSubsystemType;
  projectId?: string;
  parentId?: string;
  authorId?: string;
  lastEditorId?: string;
};
