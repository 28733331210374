import {ProjectDto} from '@projects-api-v2/util';

export class InitView {
  static readonly type = '[Project Entry View] Init View';
}

export class DeleteEntry {
  static readonly type = '[Project Entry View] Delete Entry';
  constructor(public entry: ProjectDto) {}
}

export class DeleteEntrySuccess {
  static readonly type = '[Project Entry View] Delete Entry Success';
  constructor(public deletedEntry: ProjectDto) {}
}

export class DeleteEntryFailure {
  static readonly type = '[Project Entry View] Delete Entry Failure';
  constructor(public error: string) {}
}
