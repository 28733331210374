/**
 * @todo Subsystem следует отделить от понятия GenerationTask
 */

export enum GenerationTaskSubsystemType {
  Layout = 'Layout',
  LayoutSections = 'LayoutSections',
  Structure = 'Structure',
  Structure2 = 'Structure2',
  HVAC = 'HVAC',
  Plumbing = 'Plumbing',
  Electrical = 'Electrical',
  Mesh = 'Mesh',
  SpacePlanning = 'SpacePlanning',
  OMAR = 'OMAR',
  OMAR2 = 'OMAR2',
  Final = 'Final',
}

export const GenerationTaskSubsystemName: {
  [key in GenerationTaskSubsystemType]: string;
} = {
  [GenerationTaskSubsystemType.Layout]: 'КЗ',
  [GenerationTaskSubsystemType.LayoutSections]: 'КЗ',
  [GenerationTaskSubsystemType.Structure]: 'КР',
  [GenerationTaskSubsystemType.Structure2]: 'КР 2',
  [GenerationTaskSubsystemType.Mesh]: 'КР',
  [GenerationTaskSubsystemType.HVAC]: 'ОВиК',
  [GenerationTaskSubsystemType.Plumbing]: 'ВК',
  [GenerationTaskSubsystemType.Electrical]: 'ЭОМ',
  [GenerationTaskSubsystemType.SpacePlanning]: 'ОПР',
  [GenerationTaskSubsystemType.OMAR]: 'АР',
  [GenerationTaskSubsystemType.OMAR2]: 'АР 2',
  [GenerationTaskSubsystemType.Final]: 'Финал',
};

export const GenerationTaskSubsystemNameTogether: {
  [key in GenerationTaskSubsystemType]: string;
} = {
  [GenerationTaskSubsystemType.Layout]: 'КЗ',
  [GenerationTaskSubsystemType.LayoutSections]: 'КЗ',
  [GenerationTaskSubsystemType.Structure]: 'КР',
  [GenerationTaskSubsystemType.Structure2]: 'КР_2',
  [GenerationTaskSubsystemType.Mesh]: 'КР',
  [GenerationTaskSubsystemType.HVAC]: 'ОВиК',
  [GenerationTaskSubsystemType.Plumbing]: 'ВК',
  [GenerationTaskSubsystemType.Electrical]: 'ЭОМ',
  [GenerationTaskSubsystemType.SpacePlanning]: 'ОПР',
  [GenerationTaskSubsystemType.OMAR]: 'АР',
  [GenerationTaskSubsystemType.OMAR2]: 'АР_2',
  [GenerationTaskSubsystemType.Final]: 'Финал',
};

export const GenerationTaskSubsystemIcon: {
  [key in GenerationTaskSubsystemType]: string;
} = {
  [GenerationTaskSubsystemType.Layout]: 'tuiIconTableLarge',
  [GenerationTaskSubsystemType.LayoutSections]: 'tuiIconTableLarge',
  [GenerationTaskSubsystemType.Structure]: 'tuiIconBoxLarge',
  [GenerationTaskSubsystemType.Structure2]: 'tuiIconBoxLarge',
  [GenerationTaskSubsystemType.Mesh]: 'tuiIconBoxLarge',
  [GenerationTaskSubsystemType.HVAC]: 'tuiIconWindLarge',
  [GenerationTaskSubsystemType.Plumbing]: 'tuiIconDropletLarge',
  [GenerationTaskSubsystemType.Electrical]: 'tuiIconZapLarge',
  [GenerationTaskSubsystemType.SpacePlanning]: 'tuiIconLayersLarge',
  [GenerationTaskSubsystemType.OMAR]: 'tuiIconCodesandboxLarge',
  [GenerationTaskSubsystemType.OMAR2]: 'tuiIconCodesandboxLarge',
  [GenerationTaskSubsystemType.Final]: 'tuiIconCommandLarge',
};
