export interface RoomsDto {
  readonly id: string;
  readonly name: string;
  readonly storeyId: string;
  readonly roomType: number;
  readonly globalRoomType: number;
  readonly geometry: {
    readonly profile: Array<Record<string, any>>; // [{}];
    readonly innerProfiles: Array<Array<Record<string, any>>>; // [[{}]];
    readonly height: number;
  };
  readonly parentId: string;
  readonly functionalZoneId: string;
  readonly wallReferences: string[];
  readonly floorReferences: string[];
  readonly area: number;
  readonly areaWithFinishing: number;
  readonly isLiving: boolean;
  readonly isHeated: boolean;
  readonly areaCoefficient: number;
}
