import {NgClass} from '@angular/common';
import {AfterViewInit, Component, Input} from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {SUBSYSTEM_ROUTE} from '@common/consts';
import {GenerationTaskSubsystemIcon, GenerationTaskSubsystemName} from '@generation-api-v2/util';
import {TuiSvgModule} from '@taiga-ui/core';

import {SubsystemBreadcrumbsLink} from './subsystem-breadcrumbs-link';

@Component({
  standalone: true,
  imports: [
    // Angular
    RouterLink,
    NgClass,

    // Taiga
    TuiSvgModule,
  ],
  selector: 'subsystem-breadcrumbs-link',
  templateUrl: './subsystem-breadcrumbs-link.component.html',
  styleUrl: './subsystem-breadcrumbs-link.component.scss',
})
export class SubsystemBreadcrumbsLinkComponent implements AfterViewInit {
  @Input()
  relativeTo: ActivatedRoute;

  @Input()
  set breadcrumbsLink(link: SubsystemBreadcrumbsLink) {
    this.innerLink = link;
    this.icon = GenerationTaskSubsystemIcon[link.subsystem];
    this.name = GenerationTaskSubsystemName[link.subsystem];

    if (!link.variantId) {
      return;
    }

    this.link = [SUBSYSTEM_ROUTE[link.subsystem], link.generationTaskId, 'variants', link.variantId];
  }

  icon: string;
  name: string;
  link: string[];
  innerLink: SubsystemBreadcrumbsLink;

  ngAfterViewInit() {
    document.querySelector('.active.link')?.scrollIntoView();
  }
}
