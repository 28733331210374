import {GenerationTaskDataCatalogReferenceDto} from './generation-task-data-catalog-reference.dto';
import {GenerationTaskDataHardcodeReferenceDto} from './generation-task-data-hardcode-reference.dto';

/**
 * Интерфейс получаемый из JSON-объекта, хранимого
 * в БД в виде строки для каждого задания.
 */
export interface GenerationTaskUpdateDataDto<D, H extends GenerationTaskDataHardcodeReferenceDto = {}> {
  data?: {
    /**
     * Основные свойства задания для соответствующей подсистемы.
     */
    readonly generationData: D | null;
    /**
     * В себе должен содержать захардкоженные свойства из задания. Будут
     * подставлены в задание как расширение основной модели, поэтому заполнять
     * нужно в соответствии с **временным** интерфейсом {@link GenerationTaskDataHardcodeReferenceDto}
     */
    readonly referenceDataHardcoded?: H | null;
    /**
     * Объект информации о прилинкованном справочнике. {@link GenerationTaskDataCatalogReferenceDto}
     */
    readonly referenceData?: GenerationTaskDataCatalogReferenceDto | null;
  };
  description?: string;
}
