import {Directive, ElementRef, HostListener, inject, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: 'img[imgLoadErrorFallback]',
})
export class ImageLoadFallbackDirective {
  private readonly elementRef: ElementRef<HTMLImageElement> = inject(ElementRef);

  @Input()
  imgLoadErrorFallback: string;

  loadFailure = false;

  @HostListener('error')
  onImgError(): void {
    if (!this.loadFailure) {
      this.elementRef.nativeElement.src = this.imgLoadErrorFallback || '/assets/images/img-not-found.svg';
      this.loadFailure = true;
    }
  }
}
