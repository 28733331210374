import {animate, AnimationOptions, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, HostBinding, Inject, Self} from '@angular/core';
import {TuiDestroyService, TuiDialog} from '@taiga-ui/cdk';
import {TuiDialogCloseService} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT, PolymorpheusModule} from '@tinkoff/ng-polymorpheus';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {SidePanelOptions} from './side-panel-options';

const DURATION = {params: {duration: 300}};
const TRANSITION = '{{duration}}ms ease-in-out';

@Component({
  standalone: true,
  imports: [PolymorpheusModule],
  selector: 'side-panel',
  templateUrl: './side-panel.template.html',
  styleUrls: ['./side-panel.style.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [TuiDestroyService, TuiDialogCloseService],
  animations: [
    trigger('slideInRight', [
      transition(
        ':enter',
        [style({transform: 'translateX(100%)'}), animate(TRANSITION, style({transform: 'translateX(0)'}))],
        DURATION,
      ),
      transition(
        ':leave',
        [style({transform: 'translateX(0)'}), animate(TRANSITION, style({transform: 'translateX(100%)'}))],
        DURATION,
      ),
    ]),
    trigger('fadeIn', [
      transition(':enter', [style({opacity: 0}), animate(TRANSITION, style({opacity: 1}))], DURATION),
      transition(':leave', [style({opacity: 1}), animate(TRANSITION, style({opacity: 0}))], DURATION),
    ]),
  ],
  host: {
    'automation-id': 'sidepanel',
  },
})
/** SidePanelComponent<OutputModel, InputModel> */
export class SidePanelComponent<O, I> {
  private readonly animation: {value: string} & AnimationOptions = {
    value: '',
    params: {
      start: '40px',
      duration: 300,
    },
  } as const;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<SidePanelOptions<I>, O>,
    @Inject(TuiDestroyService) @Self() destroy$: Observable<void>,
    @Inject(TuiDialogCloseService) private readonly close$: Observable<unknown>,
  ) {
    close$.pipe(takeUntil(destroy$)).subscribe(() => this.context.$implicit.complete());
  }

  @HostBinding('@slideInRight')
  @HostBinding('@fadeIn')
  get slideInRight(): {value: string} & AnimationOptions {
    return this.animation;
  }
}
