import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../enums';
import {GenerationVariantDto} from './generation-variant.dto';

export interface GenerationTaskWithSourceVariantDto {
  readonly id: string;
  readonly sourceVariant: GenerationVariantDto;
  readonly number: string;
  readonly status: GenerationTaskStatusEnum;
  readonly subsystem: GenerationTaskSubsystemType;
  readonly projectId: string;
  readonly parentId: string;
  readonly authorId: string;
  readonly lastEditorId: string;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly createdAt: string;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly lastModifiedAt: string;
}
