import {LayoutApartmentSettingsDto} from './layout-apartment-settings.dto';
import {LayoutTowerHallwaySettingsDto} from './layout-tower-hallway-settings.dto';
import {LayoutTowerLluSettingsDto} from './layout-tower-llu-settings.dto';

/**
 * @alias TowerSectionLluHallwaySettings
 */
export type LayoutTowerSectionLluHallwaySettingsDto = {
  apartmentSettings: LayoutApartmentSettingsDto;
  hallWaySettings: LayoutTowerHallwaySettingsDto;
  lluSettings: LayoutTowerLluSettingsDto;
};
