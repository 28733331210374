import {DoorsDto} from './doors.dto';
import {ElementTypesDto} from './element-types.dto';
import {ElevatorsDto} from './elevators.dto';
import {EngineeringZonesDto} from './engineering-zones.dto';
import {EquipmentDto} from './equipment.dto';
import {FacadeWallGroupsDto} from './facade-wall-groups.dto';
import {FireproofZonesDto} from './fireproof-zones.dto';
import {FloorsDto} from './floors.dto';
import {FurnitureDto} from './furniture.dto';
import {MaterialDto} from './material.dto';
import {OpeningsDto} from './openings.dto';
import {RoomSeparationsDto} from './room-separations.dto';
import {RoomsDto} from './rooms.dto';
import {StairRunsDto} from './stair-runs.dto';
import {StepLaddersDto} from './step-ladders.dto';
import {WallsDto} from './walls.dto';

export interface SimpleApartmentModelDto {
  readonly roomCount: number;
  readonly format: number;
  readonly name: string;
  readonly apartmentId: string;
  readonly slotId: string;
  readonly elements: {
    readonly walls: WallsDto[];
    readonly facadeWallGroups: FacadeWallGroupsDto[];
    readonly floors: FloorsDto[];
    readonly rooms: RoomsDto[];
    readonly furniture: FurnitureDto[];
    readonly equipment: EquipmentDto[];
    readonly openings: OpeningsDto[];
    readonly doors: DoorsDto[];
    readonly roomSeparations: RoomSeparationsDto[];
    readonly stairRuns: StairRunsDto[];
    readonly engineeringZones: EngineeringZonesDto[];
    readonly fireproofZones: FireproofZonesDto[];
    readonly elevators: ElevatorsDto[];
    readonly stepLadders: StepLaddersDto[];
  };
  readonly library: {
    readonly elementTypes: ElementTypesDto[];
    readonly materials: MaterialDto[];
  };
}
