import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {DeleteEntrySuccess as DeleteEntryFromListSuccess} from '@project/data-access/cards-view';
import {UpdateSuccess} from '@project/data-access/entry-form';
import {DeleteEntrySuccess} from '@project/data-access/entry-view';
import {UpdateSuccess as RotationUpdateSuccess} from '@project/data-access/rotation-form';
import {ProjectApiService} from '@projects-api-v2/data-access';
import {ProjectDto} from '@projects-api-v2/util';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {GetData, GetDataFailure, GetDataSuccess, InitView} from './actions';

export interface State {
  projectId: string | null;

  data: ProjectDto | null;
  error: string | null;
  loading: boolean;
}

export const defaultState: State = {
  projectId: null,

  data: null,
  error: null,
  loading: false,
};

@Injectable()
@State<State>({
  name: 'projectEntryViewShell',
  defaults: defaultState,
})
export class ProjectEntryViewShellState {
  private readonly projectApiService = inject(ProjectApiService);

  @Selector()
  static projectId(state: State): string | null {
    return state.projectId;
  }

  @Selector()
  static data(state: State): ProjectDto | null {
    return state.data;
  }

  @Selector()
  static loading(state: State): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: State): string {
    return state.error;
  }

  @Action(InitView)
  initView(ctx: StateContext<State>, payload: InitView) {
    const state = ctx.getState();

    if ((state.data === null && state.loading !== true) || state.data.id !== payload.projectId) {
      ctx.setState({...defaultState, projectId: payload.projectId});

      return ctx.dispatch(new GetData());
    }

    return EMPTY;
  }

  @Action([DeleteEntrySuccess, DeleteEntryFromListSuccess])
  deleteEntrySuccess(ctx: StateContext<State>) {
    ctx.setState({...defaultState});
  }

  @Action([UpdateSuccess, RotationUpdateSuccess])
  entryFormApiActionSuccess(ctx: StateContext<State>) {
    ctx.dispatch(new GetData());
  }

  @Action(GetData)
  getData(ctx: StateContext<State>) {
    const state = ctx.getState();

    ctx.patchState({loading: true});

    return this.projectApiService.getById(state.projectId).pipe(
      switchMap(apiResponse => ctx.dispatch(new GetDataSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new GetDataFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new GetDataFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(GetDataSuccess)
  getDataSuccess(ctx: StateContext<State>, payload: GetDataSuccess) {
    ctx.patchState({
      data: payload.apiResponse,
      loading: false,
    });
  }

  @Action(GetDataFailure)
  getDataFailure(ctx: StateContext<State>, payload: GetDataFailure) {
    ctx.patchState({
      error: payload.error,
      loading: false,
    });
  }
}
