/**
 * Интерфейс получаемый из JSON-объекта, хранимого
 * в БД в виде строки для каждого задания.
 *
 * Содержит свойства с моделями захардкоженных справочников
 */
export interface GenerationTaskDataHardcodeReferenceDto {
  /** Каждый справочник имеют свою внутреннюю структуру */
  readonly [catalogKey: string]: any;
}
