import {PortfolioCreateBodyDto} from '@projects-api-v2/util';

import {EntryFormValue} from '../../types';

export function entryFormValueToCreateBody(formValue: EntryFormValue): PortfolioCreateBodyDto {
  const body: PortfolioCreateBodyDto = {
    name: formValue.name.replaceAll(/  +/g, ' ').trim(),
    city: formValue.city.replaceAll(/  +/g, ' ').trim(),
    type: 'ResidentialComplex',
    description: formValue.description.replaceAll(/  +/g, ' ').trim(),
  };

  if (formValue.imageName) {
    body.imageName = formValue.imageName;
  }

  return body;
}
