import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterLink} from '@angular/router';
import {TuiButtonModule, TuiLoaderModule, TuiSvgModule} from '@taiga-ui/core';

@Component({
  standalone: true,
  imports: [
    // Angular
    RouterLink,

    // Taiga
    TuiSvgModule,
    TuiLoaderModule,
    TuiButtonModule,
  ],
  selector: 'auth-callback',
  template: `
    <div class="block-status block-status--info">
      <div class="block-status__icon">
        <tui-loader [size]="'m'" />
      </div>
      <div class="block-status__title">Проверяем учётную запись</div>
      @defer (on timer(5000ms)) {
        <div class="block-status__message">
          Если переход не&nbsp;осуществляется автоматически&nbsp;&mdash; попробуйте перейти на&nbsp;главную страницу
        </div>
        <div class="block-status__actions">
          <a tuiButton [appearance]="'secondary'" [routerLink]="['/']">На главную</a>
        </div>
      } @placeholder {
        <div class="block-status__message">Осталось совсем чуть-чуть...</div>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent {}
