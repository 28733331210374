import {ModelInfoEntryDto} from './model-info-entry.dto';
import {ModelStoreyInfoEntryDto} from './model-storey-info-entry.dto';

export interface ModelInfoDto {
  readonly modelId: string;
  readonly created: string;

  readonly status: ModelInfoEntryDto;
  readonly score: ModelInfoEntryDto;
  readonly concreteVolume: ModelInfoEntryDto;

  readonly info: readonly ModelInfoEntryDto[];
  readonly storeyInfos: readonly ModelStoreyInfoEntryDto[];
}
