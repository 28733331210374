import {ProjectShortDto} from './project-short.dto';

/**
 * Группа проектов. Как полноценный жилой комплекс.
 *
 * Синоним: `ProjectsGroup`
 */
export interface PortfolioDto {
  readonly id: string;
  readonly organizationId: string;
  readonly name: string;
  readonly description: string | null;
  readonly authorId: string;
  readonly lastEditorId: string | null;
  readonly projectCount: number;
  readonly city: string;
  readonly lastProjects: ProjectShortDto[];
  readonly imageUrl: string | null;
}
