import {
  Button,
  Component,
  Components,
  Disposable,
  Event,
  FragmentManager,
  ToolComponent,
  UI,
  UIElement,
} from 'openbim-components';
import * as THREE from 'three';

import {Classification, FragmentClassifier} from './fragment-classifier';

export class FragmentExploder extends Component<Set<string>> implements Disposable, UI {
  static readonly uuid = 'd260618b-ce88-4c7d-826c-6debb91de3e2' as const;
  private readonly explodedFragments = new Set<string>();

  enabled = false;
  height = 10;
  /** Group name wich will be used for explode */
  systemName = 'storeys';

  uiElement = new UIElement<{main: Button}>();

  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  constructor(components: Components) {
    super(components);

    components.tools.add(FragmentExploder.uuid, this);

    if (components.uiEnabled) {
      this.setupUI(components);
    }
  }

  get(): Set<string> {
    return this.explodedFragments;
  }

  async dispose() {
    this.explodedFragments.clear();
    await this.uiElement.dispose();
    await this.onDisposed.trigger(FragmentExploder.uuid);
    this.onDisposed.reset();
  }

  explode() {
    this.enabled = true;
    this.update();
  }

  reset() {
    this.enabled = false;
    this.update();
  }

  update() {
    const classifier = this.components.tools.get(FragmentClassifier);
    const classification: Classification = classifier.get();
    const fragments = this.components.tools.get(FragmentManager);

    const transformMatrix = new THREE.Matrix4();
    const factor = this.enabled ? 1 : -1;

    const systemClasses = classification[this.systemName];

    let i = 0;

    for (const [classId, fragmentsIdsMap] of Object.entries(systemClasses)) {
      transformMatrix.elements[13] = i * factor * this.height;

      for (const [fragmentId, idsSet] of Object.entries(fragmentsIdsMap)) {
        const fragment = fragments.list[fragmentId];
        const fragmentInClassId = `${classId}:${fragmentId}`;

        const areExploded = this.explodedFragments.has(fragmentInClassId);

        if (!fragment || (this.enabled && areExploded) || (!this.enabled && !areExploded)) {
          continue;
        }

        if (this.enabled) {
          this.explodedFragments.add(fragmentInClassId);
        } else {
          this.explodedFragments.delete(fragmentInClassId);
        }

        fragment.applyTransform(idsSet, transformMatrix);

        for (const [, subFragment] of Object.entries(fragment.fragments)) {
          subFragment.applyTransform(idsSet, transformMatrix);
        }
      }

      i++;
    }
  }

  private setupUI(components: Components) {
    const main = new Button(components);

    this.uiElement.set({main});
    main.tooltip = 'Разбить на этажи';
    main.materialIcon = 'splitscreen';
    main.onClick.add(async () => {
      if (this.enabled) {
        this.reset();
      } else {
        this.explode();
      }
    });
  }
}

ToolComponent.libraryUUIDs.add(FragmentExploder.uuid);
