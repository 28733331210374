import * as FRAGS from 'bim-fragment';
import {FragmentsGroup} from 'bim-fragment';
import {
  Button,
  Component,
  Components,
  Disposable,
  Event,
  FragmentManager,
  IfcJsonExporter,
  ToastNotification,
  ToolComponent,
  UI,
  UIElement,
} from 'openbim-components';
import {
  CivilReader,
  IfcFragmentSettings,
  IfcMetadataReader,
} from 'openbim-components/src/fragments/FragmentIfcLoader/src';
import {SpatialStructure} from 'openbim-components/src/fragments/FragmentIfcLoader/src/spatial-structure';
import * as THREE from 'three';
import {FILE_DESCRIPTION, FILE_NAME, FlatMesh, IfcAPI, IFCOPENINGELEMENT, IFCSPACE} from 'web-ifc';

export class FragmentIfcLoader extends Component<null> implements Disposable, UI {
  static readonly uuid = 'a659add7-1418-4771-a0d6-7d4d438e4624' as const;

  private readonly defaultMaterial = new THREE.MeshLambertMaterial();
  private readonly defaultMaterialTransparent = new THREE.MeshLambertMaterial({
    transparent: true,
    opacity: 0.5,
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _spatialTree = new SpatialStructure();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _metaData = new IfcMetadataReader();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _fragmentInstances = new Map<string, Map<number, FRAGS.Item>>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _civil = new CivilReader();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _propertyExporter = new IfcJsonExporter();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _visitedFragments = new Map<string, {index: number; fragment: FRAGS.Fragment}>();

  private get webIfc(): Promise<IfcAPI> {
    return (async () => {
      if ((<any & {IfcAPI?: IfcAPI}>globalThis).IfcAPI !== undefined) {
        return (<any>globalThis).IfcAPI;
      }

      const ifcAPI = new IfcAPI();
      const {path, absolute, logLevel} = this.settings.wasm;

      ifcAPI.SetWasmPath(path, absolute);
      await ifcAPI.Init();

      if (logLevel) {
        ifcAPI.SetLogLevel(logLevel);
      }

      (<any>globalThis).IfcAPI = ifcAPI;

      return (<any>globalThis).IfcAPI;
    })();
  }

  readonly onIfcLoaded = new Event<FragmentsGroup>();
  readonly onIfcStartedLoading = new Event<void>();

  readonly onSetup = new Event<void>();

  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<string>();

  settings = new IfcFragmentSettings();
  enabled = true;
  uiElement = new UIElement<{main: Button; toast: ToastNotification}>();

  constructor(components: Components) {
    super(components);
    this.components.tools.add(FragmentIfcLoader.uuid, this);

    if (components.uiEnabled) {
      this.setupUI();
    }

    this.settings.excludedCategories.add(IFCOPENINGELEMENT);
  }

  get(): null {
    return null;
  }

  async dispose() {
    this.onIfcLoaded.reset();
    await this.uiElement.dispose();
    (this.webIfc as any) = null;
    await this.onDisposed.trigger(FragmentIfcLoader.uuid);
    this.onDisposed.reset();
  }

  async setup(config?: Partial<IfcFragmentSettings>) {
    this.settings = {...this.settings, ...config};
    await this.onSetup.trigger();
  }

  async load(data: Uint8Array, coordinate = true) {
    const webIfc = await this.webIfc;
    const before = performance.now();

    await this.onIfcStartedLoading.trigger();

    await this.readIfcFile(data);
    const group = await this.getAllGeometries();

    const properties = await this._propertyExporter.export(webIfc, 0);

    group.setLocalProperties(properties);

    this.cleanUp();
    console.info(`Streaming the IFC took ${performance.now() - before} ms!`);

    const fragments = this.components.tools.get(FragmentManager);

    fragments.groups.push(group);

    for (const frag of group.items) {
      fragments.list[frag.id] = frag;
      frag.mesh.uuid = frag.id;
      frag.group = group;
      this.components.meshes.add(frag.mesh);
    }

    if (coordinate) {
      fragments.coordinate([group]);
    }

    await this.onIfcLoaded.trigger(group);

    return group;
  }

  async readIfcFile(data: Uint8Array) {
    return (await this.webIfc).OpenModel(data, this.settings.webIfc);
  }

  cleanUp() {
    (this.webIfc as any) = null;
    // this.webIfc = new IfcAPI();
    this._visitedFragments.clear();
    this._fragmentInstances.clear();
  }

  private setupUI() {
    const main = new Button(this.components);

    main.materialIcon = 'upload_file';
    main.tooltip = 'Load IFC';

    const toast = new ToastNotification(this.components, {
      message: 'IFC model successfully loaded!',
    });

    main.onClick.add(() => {
      const fileOpener = document.createElement('input');

      fileOpener.type = 'file';
      fileOpener.accept = '.ifc';
      fileOpener.style.display = 'none';

      fileOpener.onchange = async () => {
        const fragments = this.components.tools.get(FragmentManager);

        if (fileOpener.files === null || fileOpener.files.length === 0) {
          return;
        }

        const file = fileOpener.files[0];
        const buffer = await file.arrayBuffer();
        const data = new Uint8Array(buffer);
        const model = await this.load(data);
        const scene = this.components.scene.get();

        scene.add(model);

        toast.visible = true;
        await fragments.updateWindow();
        fileOpener.remove();
      };

      fileOpener.click();
    });

    this.components.ui.add(toast);
    toast.visible = false;

    this.uiElement.set({main, toast});
  }

  // eslint-disable-next-line max-statements
  private async getAllGeometries() {
    const webIfc = await this.webIfc;

    // Precompute the level and category to which each item belongs
    this._spatialTree.setUp(webIfc);

    const allIfcEntities = webIfc.GetIfcEntityList(0);

    const group = new FRAGS.FragmentsGroup();

    group.ifcMetadata = {
      name: this._metaData.get(webIfc, FILE_NAME),
      description: this._metaData.get(webIfc, FILE_DESCRIPTION),
      schema: (webIfc.GetModelSchema(0) as FRAGS.IfcSchema) || 'IFC2X3',
      maxExpressID: webIfc.GetMaxExpressID(0),
    };

    const ids: number[] = [];

    for (const type of allIfcEntities) {
      if (!webIfc.IsIfcElement(type) && type !== IFCSPACE) {
        continue;
      }

      if (this.settings.excludedCategories.has(type)) {
        continue;
      }

      const result = webIfc.GetLineIDsWithType(0, type);
      const size = result.size();

      for (let i = 0; i < size; i++) {
        const itemID = result.get(i);

        ids.push(itemID);
        const level = this._spatialTree.itemsByFloor[itemID] || 0;

        group.data.set(itemID, [[], [level, type]]);
      }
    }

    this._spatialTree.cleanUp();

    webIfc.StreamMeshes(0, ids, async mesh => {
      await this.getMesh(mesh, group);
    });

    for (const entry of this._visitedFragments) {
      const {index, fragment} = entry[1];

      group.keyFragments.set(index, fragment.id);
    }

    for (const fragment of group.items) {
      const fragmentData = this._fragmentInstances.get(fragment.id);

      if (!fragmentData) {
        throw new Error('Fragment not found!');
      }

      const items: FRAGS.Item[] = [];

      for (const [, item] of fragmentData) {
        items.push(item);
      }

      fragment.add(items);
    }

    const matrix = webIfc.GetCoordinationMatrix(0);

    group.coordinationMatrix.fromArray(matrix);
    group.civilData = this._civil.read(webIfc);

    return group;
  }

  // eslint-disable-next-line max-statements
  private async getMesh(mesh: FlatMesh, group: FRAGS.FragmentsGroup) {
    const size = mesh.geometries.size();

    const id = mesh.expressID;

    for (let i = 0; i < size; i++) {
      const geometry = mesh.geometries.get(i);

      const {x, y, z, w} = geometry.color;
      const transparent = w !== 1;
      const {geometryExpressID} = geometry;
      const geometryID = `${geometryExpressID}-${transparent}`;

      // Create geometry if it doesn't exist

      if (!this._visitedFragments.has(geometryID)) {
        const bufferGeometry = this.getGeometry(await this.webIfc, geometryExpressID);

        const material = transparent ? this.defaultMaterialTransparent : this.defaultMaterial;
        const fragment = new FRAGS.Fragment(bufferGeometry, material, 1);

        group.add(fragment.mesh);
        group.items.push(fragment);

        const index = this._visitedFragments.size;

        this._visitedFragments.set(geometryID, {index, fragment});
      }

      // Save this instance of this geometry

      const color = new THREE.Color().setRGB(x, y, z, 'srgb');
      const transform = new THREE.Matrix4();

      transform.fromArray(geometry.flatTransformation);

      const fragmentData = this._visitedFragments.get(geometryID);

      if (fragmentData === undefined) {
        throw new Error('Error getting geometry data for streaming!');
      }

      const data = group.data.get(id);

      if (!data) {
        throw new Error('Data not found!');
      }

      data[0].push(fragmentData.index);

      const {fragment} = fragmentData;

      if (!this._fragmentInstances.has(fragment.id)) {
        this._fragmentInstances.set(fragment.id, new Map());
      }

      const instances = this._fragmentInstances.get(fragment.id);

      if (!instances) {
        throw new Error('Instances not found!');
      }

      if (instances.has(id)) {
        // This item has more than one instance in this fragment
        const instance = instances.get(id);

        if (!instance) {
          throw new Error('Instance not found!');
        }

        instance.transforms.push(transform);

        if (instance.colors) {
          instance.colors.push(color);
        }
      } else {
        instances.set(id, {id, transforms: [transform], colors: [color]});
      }
    }
  }

  private getGeometry(webIfc: IfcAPI, id: number) {
    const geometry = webIfc.GetGeometry(0, id);

    const index = webIfc.GetIndexArray(geometry.GetIndexData(), geometry.GetIndexDataSize()) as Uint32Array;

    const vertexData = webIfc.GetVertexArray(geometry.GetVertexData(), geometry.GetVertexDataSize()) as Float32Array;

    const position = new Float32Array(vertexData.length / 2);
    const normal = new Float32Array(vertexData.length / 2);

    for (let i = 0; i < vertexData.length; i += 6) {
      position[i / 2] = vertexData[i];
      position[i / 2 + 1] = vertexData[i + 1];
      position[i / 2 + 2] = vertexData[i + 2];

      normal[i / 2] = vertexData[i + 3];
      normal[i / 2 + 1] = vertexData[i + 4];
      normal[i / 2 + 2] = vertexData[i + 5];
    }

    const bufferGeometry = new THREE.BufferGeometry();
    const posAttr = new THREE.BufferAttribute(position, 3);
    const norAttr = new THREE.BufferAttribute(normal, 3);

    bufferGeometry.setAttribute('position', posAttr);
    bufferGeometry.setAttribute('normal', norAttr);
    bufferGeometry.setIndex(Array.from(index));

    geometry.delete();

    return bufferGeometry;
  }
}

ToolComponent.libraryUUIDs.add(FragmentIfcLoader.uuid);
