import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../enums';
import {GenerationVariantNodeApiDto} from './generation-variant-node-api.dto';
import {UserInfoDto} from './user-info.dto';

export interface GenerationTaskNodeDto {
  readonly id: string;
  readonly parentId: string;
  readonly description: string;
  readonly number: string;
  readonly subsystem: GenerationTaskSubsystemType;
  readonly status: GenerationTaskStatusEnum;
  readonly sourceVariant: GenerationVariantNodeApiDto;
  readonly children: GenerationTaskNodeDto[];
  readonly author: UserInfoDto;
  readonly lastEditor: UserInfoDto;
  readonly createdAt: string;
  readonly lastModifiedAt: string;
}
