import {LayoutRowHallwaySettingsDto} from './layout-row-hallway-settings.dto';
import {LayoutRowLluSettingsDto} from './layout-row-llu-settings.dto';

/**
 * @alias RowedSectionLluHallwaySettings
 */
export type LayoutRowedSectionLluHallwaySettingsDto = {
  hallWaySettings: LayoutRowHallwaySettingsDto;
  lluSettings: LayoutRowLluSettingsDto;
};
