export interface StairRunsDto {
  readonly id: string;
  readonly riserCount: number;
  readonly treadCount: number;
  readonly riserHeight: number;
  readonly treadWidth: number;
  readonly functionalZoneId: string;
  readonly landingIds: string[];
  readonly geometry: {
    readonly profile: Array<Record<string, any>>; // [{}];
    readonly innerProfiles: Array<Array<Record<string, any>>>; // [[{}]];
    readonly height: number;
  };
  readonly direction: Record<string, any>;
}
