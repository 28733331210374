import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppNavpanelComponent} from '@layout/ui/components';

@Component({
  standalone: true,
  imports: [RouterModule, AppNavpanelComponent],
  selector: 'layout-default',
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Компонент дефолтной обертки для контента.
 *
 * Состоит из боковой панели и контентной области в 100% высоты экрана.
 * Содержимое контентной области обрезается рамками бразера. Области
 * скролла необходимо настраивать в shell-компонентах ваших фичей.
 */
export class LayoutDefaultComponent {}
