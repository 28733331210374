import {LayoutFixedAxesParameterDto} from './layout-fixed-axes-parameter.dto';
import {LayoutLluPresetParameterDto} from './layout-llu-preset-parameter.dto';

/**
 * @alias PresetParameter
 */
export type LayoutPresetParameterDto = {
  lluPreset: LayoutLluPresetParameterDto;
  fixedAxes: LayoutFixedAxesParameterDto;
};
