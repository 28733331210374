import {GenerationTaskStatusEnum} from '../enums';

export const GENERATION_TASK_STATUS_NAME: {[key in GenerationTaskStatusEnum]: string} = {
  [GenerationTaskStatusEnum.Draft]: 'Черновик',
  [GenerationTaskStatusEnum.PreGeneration]: 'Подготовка',
  [GenerationTaskStatusEnum.InProgress]: 'В процессе',
  [GenerationTaskStatusEnum.WorkedOut]: 'Готово',
  [GenerationTaskStatusEnum.Declined]: 'Отменен',
  [GenerationTaskStatusEnum.Failed]: 'Ошибка',
};
