import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {IfcViewerComponent} from './ifc-viewer.component';

@NgModule({
  declarations: [IfcViewerComponent],
  exports: [IfcViewerComponent],
  imports: [CommonModule],
})
export class IfcViewerModule {}
