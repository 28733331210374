import {HttpClient, HttpResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {API_URL_PREFIX} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of, switchMap, timer} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlumbingApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getDiagnosticIfc(variantId: string, type: 'building' | 'storey' = 'building'): Observable<Blob> {
    const getRequest$ = (): Observable<Blob> =>
      this.http
        .get(`${this.gatewayUrl}/${this.apiPrefix}/Plumbing/${variantId}/ifc/${type}`, {
          observe: 'response',
          responseType: 'blob',
        })
        .pipe(
          switchMap((response: HttpResponse<Blob>) => {
            if (response.status === 202 || response.status === 204) {
              return timer(3000).pipe(switchMap(getRequest$));
            }

            return of(response.body);
          }),
        );

    return getRequest$();
  }
}
