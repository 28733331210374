<div class="links-group">
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'organizations']">
    <tui-svg src="tuiIconBoxLarge" class="link__icon"></tui-svg>
    <span class="link__text">Организации</span>
  </a>
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'portfolio']">
    <tui-svg src="tuiIconBriefcaseLarge" class="link__icon"></tui-svg>
    <span class="link__text">Проекты</span>
  </a>
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'references']">
    <tui-svg src="tuiIconBookLarge" class="link__icon"></tui-svg>
    <span class="link__text">Справочники</span>
  </a>
  <!-- <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'standarts']">
    <tui-svg src="tuiIconFolderLarge" class="link__icon"></tui-svg>
    <span class="link__text">Стандарты</span>
    </a>
    <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'brand-lists']">
    <tui-svg src="tuiIconFileLarge" class="link__icon"></tui-svg>
    <span class="link__text">Бренд-листы</span>
    </a>
    <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'modules']">
    <tui-svg src="tuiIconGridLarge" class="link__icon"></tui-svg>
    <span class="link__text">Модули</span>
    </a>
    <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'stats']">
    <tui-svg src="tuiIconBarChartLarge" class="link__icon"></tui-svg>
    <span class="link__text">Статистика</span>
    </a>
    <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'admin']">
    <tui-svg src="tuiIconMonitorLarge" class="link__icon"></tui-svg>
    <span class="link__text">Управление</span>
    </a> -->
</div>
<div class="links-group links-group--bring-to-bot">
  <a
    href="https://forms.yandex.ru/cloud/65b1fedef47e73517583ffbb/"
    rel="noreferrer"
    target="_blank"
    class="links-group__entry link"
  >
    <tui-svg src="tuiIconLifeBuoyLarge" class="link__icon"></tui-svg>
    <span class="link__text">Написать в поддержку</span>
  </a>
  <a href="https://docs.platforma.build/" rel="noreferrer" target="_blank" class="links-group__entry link">
    <tui-svg src="tuiIconBookOpenLarge" class="link__icon"></tui-svg>
    <span class="link__text">Справка</span>
  </a>
</div>
<!-- <div class="links-group">
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'favorites']">
  <tui-svg src="tuiIconHeartLarge" class="link__icon"></tui-svg>
  <span class="link__text">Избранное</span>
  </a>
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'compare']">
  <tui-svg src="tuiIconBarChartLarge" class="link__icon"></tui-svg>
  <span class="link__text">Сравнение</span>
  </a>
  </div> -->
