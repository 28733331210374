import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {convertBlobError} from '@common/operators';
import {ArtefactsType} from '@common/types';
import {API_URL_PREFIX} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class Structure2ApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(
    generationVariantId: string,
    artefactsType?: ArtefactsType,
    IsAnalyticalModel?: boolean,
  ): Observable<Blob> {
    const params: {artefactsType?: ArtefactsType; IsAnalyticalModel?: boolean} = {
      IsAnalyticalModel,
      artefactsType,
    };

    return this.http
      .get(`${this.gatewayUrl}/${this.apiPrefix}/StructureTwo/${generationVariantId}/resource`, {
        params: {
          ...params,
        },
        responseType: 'blob',
      })
      .pipe(convertBlobError());
  }
}
