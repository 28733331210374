import {LayoutAxisStepSizeDto} from './layout-axis-step-size.dto';
import {LayoutTowerLluOverrideSettingsDto} from './layout-tower-llu-override-settings.dto';

/**
 * @alias TowerSectionLluHallwaySettings
 */
export type LayoutTowerLluSettingsDto = {
  minimalHeight: number;
  minimalWidth: number;
  minimalHeightForMinimalWidth: number;
  horizontalStepsSize: LayoutAxisStepSizeDto;
  verticalStepsSize: LayoutAxisStepSizeDto;
  lluSizeNeedsAdjustmentOnFloorCount: boolean;
  overrides: LayoutTowerLluOverrideSettingsDto;
};
