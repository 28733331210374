import {PortfolioDto} from '@projects-api-v2/util';

import {EntryFormValue} from '../../types';

export function entryDtoToFormValue(entry: PortfolioDto): EntryFormValue {
  return {
    name: entry.name,
    city: entry.city,
    description: entry.description,
    imageName: entry.imageUrl?.replace('images/portfolio/', '') || null,
  };
}
