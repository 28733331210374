import {ArtefactsType} from '../../enums';

export type StructureRankingArtefactsType =
  | ArtefactsType.AIIFEA
  | ArtefactsType.AIIFEM
  | ArtefactsType.AIIOAM
  | ArtefactsType.Contract
  | ArtefactsType.Dxf
  | ArtefactsType.FeaResult
  | ArtefactsType.FeaTask
  | ArtefactsType.Ifc
  | ArtefactsType.Preview
  | ArtefactsType.Report;
