import {ProjectDto} from '@projects-api-v2/util';

export class InitView {
  static readonly type = '[Project Entry View Shell] Init View';
  constructor(public projectId: string) {}
}

export class GetData {
  static readonly type = '[Project Entry View Shell] Get Data';
}

export class GetDataSuccess {
  static readonly type = '[Project Entry View Shell] Get Data Success';
  constructor(public apiResponse: ProjectDto) {}
}

export class GetDataFailure {
  static readonly type = '[Project Entry View Shell] Get Data Failure';
  constructor(public error: string) {}
}
