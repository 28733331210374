export type WallGroupType =
  | 'FacadeCorner'
  | 'FacadeWithoutIntersection'
  | 'FacadeParallelWithIntersectionRoom'
  | 'FacadeParallelWithIntersectionApartment'
  | 'FacadePerpendicularWithIntersectionRoom'
  | 'FacadePerpendicularWithIntersectionApartment'
  | 'AdditionalCorner'
  | 'Additional'
  | 'SharedWrap'
  | 'StiffeningCore'
  | 'WallLLU'
  | 'WallElevator';
