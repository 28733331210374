import {DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {importProvidersFrom, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ModalsModule} from '@common/components';
import {environment} from '@env/environment';
import {NgxsRouterPluginModule, RouterStateSerializer} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';
import {SidePanelService} from '@shared/ui';
import {TuiPreviewModule} from '@taiga-ui/addon-preview';
import {tuiAsDialog} from '@taiga-ui/cdk';
import {TUI_SANITIZER, TuiAlertModule, TuiDialogModule, TuiRootModule, TuiSvgModule} from '@taiga-ui/core';
import {TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE} from '@taiga-ui/i18n';
import {TuiBlockStatusModule} from '@taiga-ui/layout';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';
import {AuthInterceptor, AuthModule} from 'angular-auth-oidc-client';
import {provideHighlightOptions} from 'ngx-highlightjs';
import {of} from 'rxjs';

import {AppComponent} from './app.component';
import {CUSTOM_ICONS_PROVIDER} from './providers';
import {routes} from './routes';
import {CustomRouterStateSerializer} from './util';

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),

    // Taiga
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiBlockStatusModule,
    TuiPreviewModule,
    TuiSvgModule,

    // Vendor
    NgxsModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),

    // Custom
    ModalsModule,

    AuthModule.forRoot({
      config: {
        responseType: 'code',
        clientId: 'platforma',

        redirectUrl: `${window.location.origin}/auth-callback`,
        postLogoutRedirectUri: `${window.location.origin}`,

        authority: environment.authUrl,
        scope: 'openid',
        secureRoutes: [environment.apiUrl],
        useRefreshToken: true,
        silentRenew: true,
        silentRenewUrl: '/silent-renew.html',
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer},
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: 'dd.MM.yyyy HH:mm',
      },
    },
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_RUSSIAN_LANGUAGE),
    },
    CUSTOM_ICONS_PROVIDER,
    importProvidersFrom(TuiRootModule),
    provideAnimations(),
    tuiAsDialog(SidePanelService),
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      languages: {
        json: () => import('highlight.js/lib/languages/json'),
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
