import {FragmentsGroup} from 'bim-fragment';
import {
  Button,
  Components,
  EdgesClipper,
  FloatingWindow,
  FragmentBoundingBox,
  FragmentIfcLoader,
  FragmentManager,
  IfcPropertiesProcessor,
  MaterialManager,
  PostproductionRenderer,
  SimpleCamera,
  SimpleGrid,
  SimpleRaycaster,
  SimpleScene,
  Toolbar,
} from 'openbim-components';
import {Vector3} from 'three';
import {Color} from 'three/src/math/Color';
import {IFCREINFORCINGBAR, IFCREINFORCINGELEMENT, IFCSPACE, IFCTENDONANCHOR} from 'web-ifc';

import {FragmentClassifier, FragmentExploder, FragmentHighlighter, FragmentTree} from './custom-components';

export class IFCViewer {
  private readonly hostElement: HTMLElement | null = null;
  private readonly canvasElement: HTMLCanvasElement | null = null;
  private readonly components = new Components();

  private scene: SimpleScene | null = null;
  private postproductionRenderer: PostproductionRenderer | null = null;
  private camera: SimpleCamera | null = null;
  private raycaster: SimpleRaycaster | null = null;
  private grid: SimpleGrid | null = null;

  private fragmentBBox: FragmentBoundingBox | null = null;
  private fragmentManager: FragmentManager | null = null;
  private fragmentIfcLoader: FragmentIfcLoader | null = null;
  // private readonly fragmentPlans: FragmentPlans | null = null;
  private fragmentTree: FragmentTree | null = null;

  private propertiesProcessor: IfcPropertiesProcessor | null = null;
  // private propertiesManager: IfcPropertiesManager | null = null;

  private highlighter: FragmentHighlighter | null = null;
  private classifier: FragmentClassifier | null = null;
  private exploder: FragmentExploder | null = null;
  // private hider: FragmentHider | null = null;
  private clipper: EdgesClipper | null = null;

  private materialManager: MaterialManager | null = null;

  private mainToolbar: Toolbar;
  private readonly mainToolbarButtonsMap = new Map<string, Button>();

  private readonly onViewerInit: () => void;

  constructor(hostElement: HTMLElement, canvasElement: HTMLCanvasElement, initCallback?: () => void) {
    this.hostElement = hostElement;
    this.canvasElement = canvasElement;
    this.onViewerInit = initCallback ?? (() => null);

    this.initialize();
  }

  async load(ifcFile: Blob): Promise<void> {
    const fragmentGroup = await this.parseIFC(ifcFile);

    await this.addFragmentsGroupToScene(fragmentGroup);
  }

  disposeAll(): void {
    this.components.dispose();
  }

  disposeModel(): void {
    // this.components.dispose();
  }

  private async initialize(): Promise<void> {
    /**
     * Базовые компоненты без которых сцена грохается
     *
     * Сцена + Рендерер + Камера + Рейкастер
     */
    this.components.scene = await this.createScene();
    this.components.renderer = this.createRenderer();
    this.components.camera = this.createCamera();
    this.components.raycaster = this.createRaycaster();

    /**
     * Обязательная ручка для инициализации компонентов, без неё
     * часть функционала отваливается. Необходимо дергать её до
     * подключения аддонов, но после подключения компонентов. Иначе всё упадёт
     */
    await this.components.init();

    /** Универсальная сетка с фогом */
    this.createGrid();

    this.components.scene.get().background = new Color('#f5f5f7');

    await this.createAdditionalComponents();

    await this.setupHighLighter();
    this.initPropertiesHighlighting();
    this.initClipperEvents();
    this.fragmentTree.init();

    /**
     * Постпродакшн жрёт около 20фпс
     */
    this.postproductionRenderer.postproduction.enabled = true;
    this.postproductionRenderer.postproduction.customEffects.outlineEnabled = true;
    this.postproductionRenderer.postproduction.customEffects.excludedMeshes.push(this.grid.get());
    this.postproductionRenderer.postproduction.setPasses({gamma: true});

    this.propertiesProcessor = new IfcPropertiesProcessor(this.components);
    /**
     * Редактирование параметров модели осуществляет IfcPropertiesManager
     * для его включения и инициализации достаточно двух строчек. Дополнительных
     * настроек у этой штуки нет.
     *
     * this.propertiesManager = new IfcPropertiesManager(this.components);
     * this.propertiesProcessor.propertiesManager = this.propertiesManager;
     */

    await this.fragmentIfcLoader.setup({
      webIfc: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        OPTIMIZE_PROFILES: true,
      },
      coordinate: false,
      autoSetWasm: false,
    });

    /** Убираем из лоудера элементы не влияющие на отображение */
    const excludedCats = [IFCTENDONANCHOR, IFCREINFORCINGBAR, IFCREINFORCINGELEMENT, IFCSPACE];

    for (const cat of excludedCats) {
      this.fragmentIfcLoader.settings.excludedCategories.add(cat);
    }

    this.initializeToolbar();

    // this.hostElement.addEventListener('mouseup', () => {
    //   this.culler.elements.needsUpdate = true;
    // });
    // this.hostElement.addEventListener('wheel', () => {
    //   this.culler.elements.needsUpdate = true;
    // });

    /** Дергаём коллбек, чтобы компонент ангуляра снаружи смог понять что вьювер готов к принятию файлов */
    this.onViewerInit();
  }

  private async createAdditionalComponents(): Promise<void> {
    this.fragmentManager = new FragmentManager(this.components);

    this.clipper = new EdgesClipper(this.components);
    this.materialManager = new MaterialManager(this.components);

    this.highlighter = new FragmentHighlighter(this.components);
    this.fragmentBBox = new FragmentBoundingBox(this.components);
    this.fragmentIfcLoader = new FragmentIfcLoader(this.components);
    // this.fragmentPlans = new FragmentPlans(this.components);
    this.fragmentTree = new FragmentTree(this.components);
    this.classifier = new FragmentClassifier(this.components);
    this.exploder = new FragmentExploder(this.components);
    // this.hider = new FragmentHider(this.components);
  }

  private async createScene(): Promise<SimpleScene> {
    this.scene = new SimpleScene(this.components);

    await this.scene.setup({
      ambientLight: {
        color: new Color('white'),
        intensity: 0.5,
      },
      directionalLight: {
        color: new Color('white'),
        position: new Vector3(5, 10, 3),
        intensity: 0.5,
      },
    });

    return this.scene;
  }

  private createRenderer(): PostproductionRenderer {
    this.postproductionRenderer = new PostproductionRenderer(this.components, this.hostElement, {
      canvas: this.canvasElement,
      alpha: true,
    });

    return this.postproductionRenderer;
  }

  private createCamera(): SimpleCamera {
    this.camera = new SimpleCamera(this.components);

    return this.camera;
  }

  private createRaycaster(): SimpleRaycaster {
    this.raycaster = new SimpleRaycaster(this.components);

    return this.raycaster;
  }

  private createGrid(): SimpleGrid {
    this.grid = new SimpleGrid(this.components, new Color('#000'));

    return this.grid;
  }

  private async setupHighLighter(): Promise<FragmentHighlighter> {
    await this.highlighter.setup({
      /**
       * Можно установить свои материалы для ховера и выделения
       */
      // selectionMaterial: HIGHLIGHT_MATERIAL,
      // hoverMaterial: HIGHLIGHT_MATERIAL,
      // autoHighlightOnClick: true,`
      cullHighlightMeshes: false,
    });

    return this.highlighter;
  }

  private async parseIFC(ifcFile: Blob): Promise<FragmentsGroup> {
    const arrayBuffer = await ifcFile.arrayBuffer();
    const unit8Array = new Uint8Array(arrayBuffer);

    return this.fragmentIfcLoader.load(unit8Array, true);
  }

  private async addFragmentsGroupToScene(fragmentGroup: FragmentsGroup): Promise<void> {
    this.updateBoundingBox(fragmentGroup);

    this.components.scene.get().add(fragmentGroup);
    /**
     * После добавления модели на сцену необхоидмо обсчитать заново
     * * классифайер - для построения дерева элементов
     * * highlighter - запускаем перерасчет подсветки моделей на сцене
     * * exploder - запускаем перерасчет подсветки моделей на сцене
     */
    await this.createClassifier(fragmentGroup);
    await this.highlighter.updateHighlight();
    this.exploder.update();
    /** Времено отключаем, так как ломает работу срезов */
    // await this.createFragmentPlans(fragmentGroup);

    /**
     * Для отображения пропертей модели их нужно распарсить. Процедура ниже
     * блокирует исполнение, поэтому чем больше модель, тем дольше сцена подвисает
     * и модель не видна. Если парсить группу непосредственно в момент выделения только частично,
     * то подвисание короче, но блокирует отображение
     */
    await this.propertiesProcessor.process(fragmentGroup);
  }

  private updateBoundingBox(model: FragmentsGroup) {
    this.fragmentBBox.add(model);
    this.updateZoomButtonListener();
  }

  private initializeToolbar(): void {
    this.mainToolbar = new Toolbar(this.components, {position: 'bottom', name: 'mainToolbar'});
    this.components.ui.addToolbar(this.mainToolbar);

    // Добавляем кнопки отдельно
    this.createZoomButton();
    this.createClipperButton();
    this.reassignExploderButton();
    this.createPropertiesButton();
    this.mainToolbar.addChild(this.fragmentTree.uiElement.get('main'));
  }

  private createZoomButton(): void {
    const zoomButton = new Button(this.components, {
      tooltip: 'Отцентрировать',
      materialIconName: 'zoom_in_map',
    });

    this.mainToolbarButtonsMap.set('zoom', zoomButton);
    this.mainToolbar.addChild(zoomButton);
  }

  private createClipperButton(): void {
    const clipperButton: Button = this.clipper.uiElement.get('main');

    clipperButton.tooltip = 'Создать сечение';
    this.mainToolbar.addChild(clipperButton);
  }

  private createPropertiesButton(): void {
    const propertiesButton: Button = this.propertiesProcessor.uiElement.get('main');
    const propertiesWindow: FloatingWindow = this.propertiesProcessor.uiElement.get('propertiesWindow');

    propertiesWindow.title = 'Свойства';
    propertiesButton.tooltip = 'Свойства';
    this.mainToolbar.addChild(propertiesButton);
  }

  private updateZoomButtonListener(): void {
    const zoomButton = this.mainToolbarButtonsMap.get('zoom');

    zoomButton.onClick.reset();
    zoomButton.onClick.add(() => {
      this.camera.controls.fitToSphere(this.fragmentBBox.getMesh(), true);
    });
  }

  private initPropertiesHighlighting(): void {
    const highlighterEvents = this.highlighter.events;

    highlighterEvents.select.onClear.add(() => {
      this.propertiesProcessor.cleanPropertiesList();
    });

    highlighterEvents.select.onHighlight.add(selection => {
      if (Object.keys(selection).length > 0) {
        const fragmentID = Object.keys(selection)[0];
        const expressID = [...selection[fragmentID]][0];
        const fragment = this.fragmentManager.list[fragmentID];

        if (fragment) {
          this.propertiesProcessor.renderProperties(fragment.group, expressID);
        }
      }
    });
  }

  private initClipperEvents(): void {
    this.canvasElement.ondblclick = () => this.clipper.create();

    window.onkeydown = event => {
      if (event.code === 'Delete' || event.code === 'Backspace') {
        this.clipper.delete();
      }
    };
  }

  private async createClassifier(fragmentGroup: FragmentsGroup): Promise<void> {
    await this.classifier.byStorey(fragmentGroup);
    this.classifier.byEntity(fragmentGroup);

    await this.fragmentTree.update(['storeys', 'entities']);

    this.fragmentTree.onSelected.add(({items, visible}) => {
      if (visible) {
        this.highlighter.highlightByID('select', items, true, true);
      }
    });

    this.fragmentTree.onHovered.add(({items, visible}) => {
      if (visible) {
        this.highlighter.highlightByID('hover', items, true, false);
      }
    });
  }

  private reassignExploderButton(): void {
    const exploderButton: Button = this.exploder.uiElement.get('main');

    exploderButton.onClick.reset();
    exploderButton.onClick.add(async () => {
      if (this.exploder.enabled) {
        this.exploder.reset();
      } else {
        this.exploder.explode();
      }
    });

    this.mainToolbar.addChild(exploderButton);
  }

  /**
   * Планы работают, но не вместе с ножницами. Из-за переиспользования
   * планами компонента срезов на модель накладываются срезы по каждому этажу по умолчанию.
   * Единственный способ адекватно с этим работать – разделять лоигку взаимодействия со срезами
   * и генерировать планы толкьо по клику на кнопку, а при выходе – очищать созданные срезы
   */
  // private async createFragmentPlans(fragmentsGroup: FragmentsGroup) {
  //   const meshes = fragmentsGroup.items.map(({mesh}) => mesh);
  //   const whiteColor = new Color('white');
  //   const whiteMaterial = new MeshBasicMaterial({color: whiteColor});

  //   this.materialManager.addMaterial('white', whiteMaterial);
  //   this.materialManager.addMeshes('white', meshes);

  //   this.canvasElement.addEventListener('click', () => this.highlighter.clear('default'));

  //   this.fragmentPlans.commands = {
  //     Select: async plan => {
  //       const found = await this.classifier.find({storeys: [plan.name]});

  //       found ?? this.highlighter.highlightByID('default', found);
  //     },
  //     Show: async plan => {
  //       const found = await this.classifier.find({storeys: [plan.name]});

  //       found ?? this.hider.set(true, found);
  //     },
  //     Hide: async plan => {
  //       const found = await this.classifier.find({storeys: [plan.name]});

  //       found ?? this.hider.set(false, found);
  //     },
  //   };

  //   this.fragmentPlans.onNavigated.add(() => {
  //     this.postproductionRenderer.postproduction.customEffects.glossEnabled = false;
  //     // this.materialManager.setBackgroundColor(whiteColor);
  //     this.materialManager.set(true, ['white']);
  //     this.grid.visible = false;
  //   });

  //   this.fragmentPlans.onExited.add(() => {
  //     this.postproductionRenderer.postproduction.customEffects.glossEnabled = true;
  //     // this.materialManager.resetBackgroundColor();
  //     this.materialManager.set(false, ['white']);
  //     this.grid.visible = true;
  //   });

  //   await this.fragmentPlans.computeAllPlanViews(fragmentsGroup);
  //   await this.fragmentPlans.updatePlansList();
  //   await this.highlighter.updateHighlight();
  // }
}
