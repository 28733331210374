import {FormControl, FormGroup} from '@angular/forms';

export type EntryFormControls = {
  readonly name: FormControl<string | null>;
  readonly city: FormControl<string | null>;
  readonly description: FormControl<string | null>;
  /**
   * Это на самом деле path до файла, который почему-то назвали Name
   */
  readonly imageName: FormControl<string | null>;
};

export type EntryFormGroup = FormGroup<EntryFormControls>;

export type EntryFormValue = {
  readonly name: string | null;
  readonly city: string | null;
  readonly description: string | null;
  readonly imageName: string | null;
};
