import {inject, Injectable} from '@angular/core';
import {AbstractTuiDialogService} from '@taiga-ui/cdk';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';

import {SidePanelComponent} from './side-panel.component';
import {SIDE_PANEL_DIALOG_OPTIONS} from './side-panel.tokens';
import {SidePanelOptions} from './side-panel-options';

const DIALOG = new PolymorpheusComponent(SidePanelComponent);

@Injectable({
  providedIn: 'root',
})
export class SidePanelService extends AbstractTuiDialogService<SidePanelOptions<unknown>> {
  protected readonly component = DIALOG;
  protected readonly defaultOptions: SidePanelOptions<unknown> = {
    ...inject(SIDE_PANEL_DIALOG_OPTIONS),
    data: undefined,
  };
}
