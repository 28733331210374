import {InjectionToken, ValueProvider} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';

import {SidePanelOptions} from './side-panel-options';

type SidePanelDefaultOptions = Omit<SidePanelOptions<unknown>, 'data'>;

export const SIDE_PANEL_DEFAULT_OPTIONS: SidePanelDefaultOptions = {
  closeable: true,
  dismissible: true,
};

/**
 * A stream to close dialogs
 */
export const SIDE_PANEL_DIALOGS_CLOSE = new InjectionToken<Observable<unknown>>('[TUI_DIALOGS_CLOSE]', {
  factory: () => EMPTY,
});

/**
 * Default parameters for dialog component
 */
export const SIDE_PANEL_DIALOG_OPTIONS = new InjectionToken<SidePanelDefaultOptions>('[TUI_DIALOG_OPTIONS]', {
  factory: () => SIDE_PANEL_DEFAULT_OPTIONS,
});

export function tuiDialogOptionsProvider(options: Partial<SidePanelDefaultOptions>): ValueProvider {
  return {
    provide: SIDE_PANEL_DIALOG_OPTIONS,
    useValue: {...SIDE_PANEL_DEFAULT_OPTIONS, ...options},
  };
}
