import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../enums';
import {UserInfoDto} from './user-info.dto';

/**
 * `D` следует использовать только для создания своих типов.
 * Использовать GenerationTaskDto<D> в коде с бизнеслогикой
 * нельзя.
 */
export interface GenerationTaskDto<D = string> {
  readonly id: string;
  readonly number: string;
  /**
   * JSON-объект конвертированный в строку. При сериализации
   * должен соответствовать интерфейсу [GenerationTaskDataDto](./generation-task-data.dto.ts)
   */
  readonly data: D;
  readonly description: string;
  readonly status: GenerationTaskStatusEnum;
  readonly subsystem: GenerationTaskSubsystemType;
  readonly projectId: string;
  readonly parentId: string;
  readonly generationId: string;
  readonly sourceVariantId: string;
  readonly author: UserInfoDto;
  readonly lastEditor: UserInfoDto;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly createdAt: string;

  /**
   * ISO 8601 UTC Date string
   * @example "2024-04-19T10:05:19.023412Z"
   */
  readonly lastModifiedAt: string;
}
