import {ModelInfoEntryDto} from './model-info-entry.dto';

export interface ShortModelInfoDto {
  readonly modelId: string;
  readonly created: string;

  readonly status: ModelInfoEntryDto;
  readonly score: ModelInfoEntryDto;
  readonly concreteVolume: ModelInfoEntryDto;
}
