import {GenerationTaskDataCatalogReferenceDto} from '../generation-task-data-catalog-reference.dto';
import {GenerationTaskDataHardcodeReferenceDto} from '../generation-task-data-hardcode-reference.dto';
import {ObjectModelBuilderOptionsDto} from './object-model-builder-options.dto';

export interface StructureObjectModelConfiguration<H extends GenerationTaskDataHardcodeReferenceDto = {}> {
  generationData: ObjectModelBuilderOptionsDto;
  referenceDataHardcoded?: H | null;
  referenceData?: GenerationTaskDataCatalogReferenceDto | null;
  clientData?: {[key: string]: any};
  enableBulkGeneration: boolean;
}
