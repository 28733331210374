<div *ngIf="user$ | async as user" class="user">
  <tui-avatar size="s" [avatarUrl]="avatarUrl || 'tuiIconUserLarge'" [rounded]="true"></tui-avatar>
  <div *ngIf="expanded" class="user__name user__name--long">
    <tui-line-clamp [content]="tooltip" [lineHeight]="24" [linesLimit]="1"></tui-line-clamp>
  </div>
  <ng-template #tooltip>
    <div>
      {{ user.userData?.name || user.userData?.email }}
    </div>
  </ng-template>
</div>

<tui-hosted-dropdown *ngIf="expanded" [content]="profileActionsTemplate">
  <button tuiButton type="button" appearance="icon" size="xs" icon="tuiIconMoreVertical"></button>
  <ng-template #profileActionsTemplate>
    <button type="button" tuiOption role="menuitemradio" appearance="flat" size="s" [disabled]="true">
      Открыть профиль
    </button>
    <button type="button" tuiOption role="menuitemradio" appearance="flat" size="s" (click)="logOut()">
      Выйти из системы
    </button>
  </ng-template>
</tui-hosted-dropdown>
