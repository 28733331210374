export interface ElementTypesDto {
  readonly id: string;
  readonly name: string;
  readonly layers: [
    {
      readonly materialId: string;
      readonly thickness: number;
    },
  ];
}
