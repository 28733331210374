import {
  EomReceptacleEquipmentDto,
  EomReceptacleFurnitureDto,
  EomReceptacleKitchenEquipmentDto,
  EomReceptacleWaterClosetDto,
} from '../../../types';

export interface EomReceptacleDto {
  readonly window: {
    readonly windowBlinds: boolean;
  };
  readonly kitchenEquipment: EomReceptacleKitchenEquipmentDto;
  readonly furniture: EomReceptacleFurnitureDto;
  readonly equipment: EomReceptacleEquipmentDto;
  readonly waterCloset: EomReceptacleWaterClosetDto;
}
