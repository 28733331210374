/**
 * @alias LayoutOptionsDto
 */
export type LayoutOptionsDto = {
  minimumAxialStep: number;
  defaultAxialStep: number;
  maximumAxialStep: number;
  facadeWallThickness: number;
  firstStoreyHeight: number;
};
