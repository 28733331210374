import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TuiButtonModule, TuiLinkModule, TuiModeModule, TuiSvgModule} from '@taiga-ui/core';
import {TuiAvatarModule} from '@taiga-ui/kit';

@Component({
  selector: 'ntc-info-btn',
  standalone: true,
  imports: [TuiButtonModule, TuiSvgModule, NgIf, TuiLinkModule, TuiAvatarModule, TuiModeModule],
  templateUrl: './info-btn.component.html',
  styleUrl: './info-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBtnComponent {}
