import {LayoutCornerHallwaySettingsDto} from './layout-corner-hallway-settings.dto';
import {LayoutCornerLluSettingsDto} from './layout-corner-llu-settings.dto';

/**
 * @alias CornerSectionLluHallwaySettings
 */
export type LayoutCornerSectionLluHallwaySettingsDto = {
  hallWaySettings: LayoutCornerHallwaySettingsDto;
  lluSettings: LayoutCornerLluSettingsDto;
};
