export type HvacSmokeVentilationSharedAreasConfigDto = {
  readonly calculationVariant: number;
  readonly smokeExhaustAirDuctStandardSpeed: number;
  readonly compensationAirDuctStandardSpeed: number;
  readonly smokeExhaustValveStandardSpeed: number;
  readonly compensationValveStandardSpeed: number;
  readonly betweenSubZoneSideEdgeAndAirDuct: number;
  readonly betweenSubZoneSideEdgeAndSmokeExhaustAirDuctForActuator: number;
  readonly betweenSubZoneBackEdgeAndAirDuct: number;
  readonly betweenEngineeringOpenings: number;
};
