import {BuildingType} from '../../types';

export interface ProjectDto {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly authorId: string;
  readonly lastEditorId: string;
  readonly portfolioId: string;
  readonly country: string;
  readonly region: string;
  readonly city: string;
  readonly postalCode: string;
  readonly rawAddress: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly altitude: number;
  readonly streetName: string;
  readonly rotationAngle: number;
  readonly buildingType: BuildingType;
  readonly previewImageUrl: string;
  readonly middleImageUrl: string;
  readonly largeImageUrl: string;
}
