<a
  class="link link--{{ innerLink.subsystem }}"
  [attr.automation-id]="'subsystem-breadcrumbs_link-' + innerLink.subsystem"
  [routerLink]="link"
  [relativeTo]="relativeTo"
  [ngClass]="{
    active: innerLink.active,
    disabled: !innerLink?.variantId,
  }"
>
  <tui-svg src="{{ icon }}" class="link__icon" />
  <span class="link__text">{{ name }}</span>
</a>
