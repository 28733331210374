export function excludeNilProps<T extends Record<string, unknown>>(rawObject: T): Partial<T> {
  const result: T = {...rawObject};

  if (rawObject) {
    Object.entries(rawObject).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        delete result[key];
      }
    });
  }

  return result;
}
