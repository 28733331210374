<ng-container *ngFor="let crumb of crumbs$ | async; let isLast = last">
  <a *ngIf="!isLast" class="link" [routerLink]="[crumb.pathFromRoot]">
    <tui-svg *ngIf="crumb.iconName !== null" class="link-icon" [src]="crumb.iconName"></tui-svg>
    <tui-line-clamp
      *ngIf="crumb.label !== null"
      class="label"
      [content]="crumb.label | async"
      [linesLimit]="1"
      [lineHeight]="20"
    ></tui-line-clamp>
  </a>
  <span *ngIf="isLast" class="current">
    <tui-line-clamp
      *ngIf="crumb.label !== null"
      class="label"
      [content]="crumb.label | async"
      [linesLimit]="1"
      [lineHeight]="20"
    ></tui-line-clamp>
  </span>
  <span *ngIf="!isLast" class="divider">/</span>
</ng-container>
