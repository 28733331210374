import {PortfolioDto} from '@projects-api-v2/util';

export class InitView {
  static readonly type = '[Portfolio Entry View] Init View';
  constructor(public portfolioId: string) {}
}

export class GetData {
  static readonly type = '[Portfolio Entry View] Get Data';
}

export class GetDataSuccess {
  static readonly type = '[Portfolio Entry View] Get Data Success';
  constructor(public apiResponse: PortfolioDto) {}
}

export class GetDataFailure {
  static readonly type = '[Portfolio Entry View] Get Data Failure';
  constructor(public error: string) {}
}

export class DeleteEntry {
  static readonly type = '[Portfolio Entry View] Delete Entry';
  constructor(public entry: PortfolioDto) {}
}

export class DeleteEntrySuccess {
  static readonly type = '[Portfolio Entry View] Delete Entry Success';
  constructor(public deletedEntry: PortfolioDto) {}
}

export class DeleteEntryFailure {
  static readonly type = '[Portfolio Entry View] Delete Entry Failure';
  constructor(public error: string) {}
}
