import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TuiLoaderModule} from '@taiga-ui/core';

@Component({
  selector: 'ntc-full-page-loader',
  standalone: true,
  imports: [TuiLoaderModule],
  templateUrl: './full-page-loader.component.html',
  styleUrl: './full-page-loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullPageLoaderComponent {}
