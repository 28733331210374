export type GenerationVariantsGetParams = {
  id?: string | null;

  /** Id задания */
  generationTaskId?: string | null;

  /** Id ОМ, который мы получаем от других модулей после запуска генерации. */
  objectModelId?: string | null;

  /** Id автора. */
  authorId?: string | null;

  /** Id последнего пользователя, отредактировавшего запись. */
  lastEditorId?: string | null;
};
