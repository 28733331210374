<div class="overflow">
  <div class="header">
    <a class="logo-link" [routerLink]="['/']">
      <app-logo class="logo" />
    </a>
  </div>
  <div class="content">
    <tui-scrollbar class="content__scrollbar">
      <app-nav class="nav scrollable" [expanded]="hovered" />
    </tui-scrollbar>
  </div>
  <div class="footer">
    <profile-card #activeZone="tuiActiveZone" [expanded]="hovered" (tuiActiveZoneChange)="onChildActiveZone($event)" />
  </div>
</div>
