import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {API_URL_PREFIX} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DebugApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  createCustomVariant(generationTaskId: string, objectModel: Blob): Observable<string> {
    const formData = new FormData();

    formData.append('objectModel', new Blob([objectModel], {type: 'application/json'}));

    return this.http.post<string>(
      `${this.gatewayUrl}/${this.apiPrefix}/Debug/generationTasks/${generationTaskId}/variants`,
      formData,
    );
  }
}
