<div>
  <img
    ngSrc="./assets/page-not-found/logo-not-found.svg"
    alt="Платформа. Научно-технический-центр"
    height="32"
    width="352"
  />
</div>

<div class="content">
  <img ngSrc="./assets/page-not-found/404.svg" alt="404" height="217" width="542" />
  <div class="title">Страница не найдена</div>
  <div class="text">Вернитесь на главную чтобы продолжить работу.</div>
  <a routerLink="/" tuiButton size="s" class="btn">Вернуться на главную</a>
</div>
