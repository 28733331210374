export type HvacDuctInsulationsConfigDto = {
  readonly verticalDuctApartmentVentilation: {
    readonly thickness: number;
    readonly fireResistanceLimit: string;
  };
  readonly horizontalDuctApartmentVentilationFromValveToWall: {
    readonly thickness: number;
    readonly fireResistanceLimit: string;
  };
  readonly verticalDuctInsideEngineeringZoneinSharedAreas: {
    readonly thickness: number;
    readonly fireResistanceLimit: string;
  };
  readonly smokeVentilationDuctsFromValveToFan: {
    readonly thickness: number;
    readonly fireResistanceLimit: string;
  };
  readonly fireSafeZoneDucts: {
    readonly thickness: number;
    readonly fireResistanceLimit: string;
  };
};
