import {ChangeDetectionStrategy, Component, HostBinding, Inject} from '@angular/core';
import {TuiButtonModule, TuiDialogContext} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';

@Component({
  standalone: true,
  imports: [TuiButtonModule],
  selector: 'approve-dialog',
  templateUrl: './approve-dialog.component.html',
  styleUrl: './approve-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveDialogComponent {
  get questionText(): string {
    return this.context?.data.text || 'Вы уверены?';
  }

  @HostBinding('class._destructive')
  get isDestructive(): boolean {
    return this.context?.data.appearance === 'destructive';
  }

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean | null, {text: string; appearance?: 'destructive' | 'primary'}>,
  ) {}

  submit(): void {
    this.context.completeWith(true);
  }

  cancel(): void {
    this.context.completeWith(false);
  }
}
