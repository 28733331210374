import {BuildingType} from '../building-type';

export type ProjectPatchBodyDto = {
  name?: string;
  portfolioId?: string;
  description?: string;
  country?: string;
  region?: string;
  city?: string;
  streetName?: string;
  postalCode?: string;
  rawAddress?: string;
  longitude?: number;
  latitude?: number;
  altitude?: number;
  buildingType?: BuildingType;
  rotationAngle?: number;
  previewImageName?: string;
  middleImageName?: string;
  largeImageName?: string;
};
