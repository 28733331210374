/**
 * Интерфейс получаемый из JSON-объекта, хранимого
 * в БД в виде строки для каждого задания. Содержит
 * информацию о прилинкованном справочнике
 */
export type GenerationTaskDataCatalogReferenceBodyDto = {
  /**
   * ID прилинкованного справочника
   */
  id: string;
  /**
   * Версия прилинкованного справочника
   */
  version: number;
};

/**
 * Интерфейс получаемый из JSON-объекта, хранимого
 * в БД в виде строки для каждого задания.
 *
 * Содержит свойства с моделями захардкоженных справочников
 */
export type GenerationTaskDataHardcodeReferenceBodyDto = {
  /** Каждый справочник имеют свою внутреннюю структуру */
  [catalogKey: string]: any;
};

/**
 * Бодик для создания или обновления задания на генерацию
 */
export type GenerationTaskDataBodySeriallized<G, H extends GenerationTaskDataHardcodeReferenceBodyDto = {}> = {
  /**
   * Основные свойства задания для соответствующей подсистемы.
   *
   * Необходимо учитывать, что те свойства, которые заполняются внешними
   * справочниками должны быть исключены из `G` и добавлены в тип поля `referenceDataHardcoded`
   *
   * Например для поля 'slots':
   *
   * ```ts
   * type LayoutTaskReferenceFields = 'slots';
   * type LayoutTaskData = Omit<TaskOptionsDto, LayoutTaskReferenceFields>;
   * type LayoutTaskHardcodeData = Pick<TaskOptionsDto, LayoutTaskReferenceFields>;
   *
   * type LayoutTaskDataBody = GenerationTaskDataBodySeriallized<LayoutTaskData, LayoutTaskHardcodeData>;
   * ```
   *
   */
  generationData: G | null;
  /**
   * В себе должен содержать захардкоженные свойства из задания. Будут
   * подставлены в задание как расширение основной модели, поэтому заполнять
   * нужно в соответствии с **временным** интерфейсом {@link GenerationTaskDataHardcodeReferenceDto}
   */
  referenceDataHardcoded?: H | null;
  /**
   * Объект информации о прилинкованном справочнике. {@link GenerationTaskDataCatalogReferenceDto}
   */
  referenceData?: GenerationTaskDataCatalogReferenceBodyDto | null;

  /**
   * Для хранения информации необходимой для восстановления данных на клиенте
   * следует использовать этот объект.
   */
  clientData?: {[key: string]: any};
};
