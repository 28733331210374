export interface StepLaddersDto {
  readonly id: string;
  readonly name: string;
  readonly geometry: {
    readonly profile: Array<Record<string, any>>; // [{}];
    readonly innerProfiles: Array<Array<Record<string, any>>>; // [[{}]];
    readonly height: number;
  };
  readonly stairCount: number;
  readonly stairStep: number;
  readonly functionalZoneId: string;
}
