<tui-root class="tui-root">
  <router-outlet></router-outlet>

  @if (authConfigLoadingFailed$ | async) {
    <div class="block-status block-status--error">
      <tui-svg class="block-status__icon" [src]="'tuiIconXLarge'" />
      <div class="block-status__title">Ошибка сервиса авторизации</div>
      <div class="block-status__message">Не&nbsp;удалось получить конфигурацию для запуска процесса аутентификации</div>
    </div>
  }
</tui-root>
