export type EomSwitchDto = {
  readonly livingRoom: boolean;
  readonly kitchen: boolean;
  readonly diningRoom: boolean;
  readonly hallWay: boolean;
  readonly hall: boolean;
  readonly dressingRoom: boolean;
  readonly waterCloset: boolean;
  readonly bedRoom: boolean;
  readonly anyRoom: boolean;
};
