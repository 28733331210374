import {Params, Router} from '@angular/router';
import {ROUTES} from '@common/consts';
import {map, Observable} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function checkRouteIdParameterOperator<T>(
  router: Router,
  idName?: string,
): (source$: Observable<Params>) => Observable<number> {
  return source$ =>
    source$.pipe(
      map(params => {
        const id = idName ? Number(params[`${idName}`]) : Number(params.id);

        if (Number.isNaN(id)) {
          router.navigate([`${ROUTES.notFound}`], {skipLocationChange: true});
          throw new Error();
        }

        return id;
      }),
    );
}
