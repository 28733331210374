import {AsyncPipe, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, inject, Input} from '@angular/core';
import {TuiButtonModule, TuiDataListModule, TuiHintModule, TuiHostedDropdownModule, TuiSvgModule} from '@taiga-ui/core';
import {TuiAvatarModule, TuiLineClampModule} from '@taiga-ui/kit';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,

    TuiAvatarModule,
    TuiSvgModule,
    TuiButtonModule,
    TuiDataListModule,
    TuiHostedDropdownModule,
    TuiHintModule,
    TuiLineClampModule,
  ],
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileCardComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);

  readonly user$ = this.oidcSecurityService.userData$;

  isExpanded = false;

  @HostBinding('class._expanded')
  @Input()
  set expanded(value: boolean) {
    this.isExpanded = value;
  }

  get expanded(): boolean {
    return this.isExpanded;
  }

  avatarUrl: string = null;

  logOut() {
    /** Можно не отписываться, потому что пользователя выкинет в ауф */
    this.oidcSecurityService.logoff().subscribe();
  }
}
