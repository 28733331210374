export interface WallsDto {
  readonly id: string;
  readonly elementTypeId: string;
  readonly storeyId: string;
  readonly location: {
    readonly start: Record<string, any>;
    readonly end: Record<string, any>;
  };
  readonly locationBinding: number;
  readonly height: number;
  readonly wallPositionType: number;
  readonly connections: [
    {
      readonly connectedWallId: string;
      readonly point: {};
    },
  ];
}
