import {ProjectDto} from '@projects-api-v2/util';

export class InitView {
  static readonly type = '[Project Cards View] Init View';
  constructor(public portfolioId: string) {}
}

export class RefreshData {
  static readonly type = '[Project Cards View] Refresh Data';
}

export class GetData {
  static readonly type = '[Project Cards View] Get Data';
}

export class GetDataSuccess {
  static readonly type = '[Project Cards View] Get Data Success';
  constructor(public apiResponse: readonly ProjectDto[]) {}
}

export class GetDataFailure {
  static readonly type = '[Project Cards View] Get Data Failure';
  constructor(public error: string) {}
}

export class DeleteEntry {
  static readonly type = '[Project Cards View] Delete Entry';
  constructor(public entry: ProjectDto) {}
}

export class DeleteEntrySuccess {
  static readonly type = '[Project Cards View] Delete Entry Success';
  constructor(public deletedEntry: ProjectDto) {}
}

export class DeleteEntryFailure {
  static readonly type = '[Project Cards View] Delete Entry Failure';
  constructor(public error: string) {}
}
