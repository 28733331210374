import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TuiDialogContext} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';

import {IErrorModal} from './error-modal.interface';

@Component({
  selector: 'ntc-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorModalComponent {
  private code: any;
  needHelp = false;
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<IErrorModal, IErrorModal>,
  ) {}

  get data(): IErrorModal {
    if (this.context.data.code) {
      if (typeof this.context.data.code === 'string') {
        this.code = this.context.data.code;
      } else {
        this.code = this.context.data.code.toString();
      }

      if (this.code.startsWith('5')) {
        this.needHelp = true;
      }
    }

    return this.context.data;
  }
}
