<h2 *ngIf="data.code">{{ data.code }}</h2>
<h3 *ngIf="data.title">{{ data.title }}</h3>
<ng-container *ngFor="let item of data.messages">
  <tui-notification status="error" class="tui-space_vertical-4">
    <div class="message" [innerHTML]="item"></div>
  </tui-notification>
</ng-container>
@if (needHelp) {
  <div class="help">
    <ntc-info-btn />
  </div>
}
