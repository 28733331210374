import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  NgZone,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {BehaviorSubject, filter} from 'rxjs';

import {IFCViewer} from './util';

@Component({
  selector: 'ntc-ifc-viewer',
  templateUrl: './ifc-viewer.component.html',
  styleUrls: ['./ifc-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IfcViewerComponent implements AfterViewInit {
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly zone = inject(NgZone);

  private readonly ifcBlob$ = new BehaviorSubject<Blob | null>(null);

  @Input()
  set ifc(file: Blob | null) {
    this.ifcBlob$.next(file);
  }

  viewer: IFCViewer | null = null;

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      const hostElement = this.elementRef.nativeElement.querySelector<HTMLElement>('#ifc-wrapper');
      const canvasElement = this.elementRef.nativeElement.querySelector<HTMLCanvasElement>('#ifc-viewer');

      this.viewer = new IFCViewer(hostElement, canvasElement, () => {
        this.ifcBlob$
          .pipe(
            filter(file => file !== null),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe(file => {
            this.zone.runOutsideAngular(() => {
              this.viewer.disposeModel();
              this.viewer.load(file);
            });
          });
      });
    });
  }

  ngOnDestroy() {
    this.viewer?.disposeAll();
  }
}
