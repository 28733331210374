import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {TuiAlertService, TuiButtonModule, TuiDialogContext, TuiScrollbarModule} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import {Highlight} from 'ngx-highlightjs';

@Component({
  standalone: true,
  imports: [
    // Vendor
    Highlight,

    // Taiga
    TuiScrollbarModule,
    TuiButtonModule,
  ],
  selector: 'json-preview-dialog',
  templateUrl: './json-preview-dialog.component.html',
  styleUrl: './json-preview-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JSONPreviewDialogComponent {
  private readonly context = inject<TuiDialogContext<null, string>>(POLYMORPHEUS_CONTEXT);
  private readonly alerts = inject(TuiAlertService);
  private readonly clipboard = inject(Clipboard);

  get jsonString(): string | null {
    return this.context.data;
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.context.data ?? '');
    this.alerts.open('Успешно', {label: 'JSON скопирован в clipboard', status: 'success'}).subscribe();
  }
}
