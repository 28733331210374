/**
 * @alias AddressDto
 */
export type LayoutAddressDto = {
  country: string;
  region: string;
  city: string;
  streetName: string;
  postalCode: string;
  rawAddress: string;
};
