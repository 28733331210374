import {HvacDuctInsetsConfigDto} from '../../../types/dto/hvac/hvac-duct-insets-config.dto';
import {HvacDuctInsulationsConfigDto} from '../../../types/dto/hvac/hvac-duct-insulations-config.dto';
import {HvacSmokeVentilationSharedAreasConfigDto} from '../../../types/dto/hvac/hvac-smoke-ventilation-shared-areas-config.dto';
import {HvacTypicalStoreyRoomHeatLossConfigDto} from '../../../types/dto/hvac/hvac-typical-storey-room-heat-loss-config.dto';

export interface HvacConfigDto {
  readonly buildingRotationAngle?: number;
  readonly smokeVentilationSharedAreas?: HvacSmokeVentilationSharedAreasConfigDto;
  readonly typicalStoreyRoomHeatLoss?: HvacTypicalStoreyRoomHeatLossConfigDto;
  readonly ductInsulations?: HvacDuctInsulationsConfigDto;
  readonly ductInsets?: HvacDuctInsetsConfigDto;
}
