import {TuiContextWithImplicit, TuiIdentityMatcher, TuiStringHandler} from '@taiga-ui/cdk';

import {SelectOption} from '../types';

export const STRINGIFY_ELEMENT: TuiStringHandler<SelectOption | TuiContextWithImplicit<SelectOption>> = (
  item: SelectOption | TuiContextWithImplicit<SelectOption>,
) => ('label' in item ? `${item.label}` : `${item.$implicit.label}`);

export const ID_MATCHER_ELEMENT: TuiIdentityMatcher<SelectOption> = (item1: SelectOption, item2: SelectOption) =>
  item1.id === item2.id;
