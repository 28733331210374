import {Button, Component, Disposable, Event, FloatingWindow, FragmentIdMap, UI, UIElement} from 'openbim-components';

import {FragmentClassifier} from './fragment-classifier';
import {FragmentTreeItem} from './fragment-tree-item';

const RU_NAMES_MAPPER: {[key: string]: string | undefined} = {
  storeys: 'Этаж',
  entities: 'Элемент',
};

export class FragmentTree extends Component<FragmentTreeItem> implements UI, Disposable {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _title = 'Дерево модели';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _tree?: FragmentTreeItem;

  /** {@link Disposable.onDisposed} */
  readonly onDisposed = new Event<undefined>();

  enabled = true;
  onSelected = new Event<{items: FragmentIdMap; visible: boolean}>();
  onHovered = new Event<{items: FragmentIdMap; visible: boolean}>();

  uiElement = new UIElement<{main: Button; window: FloatingWindow}>();

  getRuClassName(className: string): string {
    return RU_NAMES_MAPPER[className] || 'Без названия';
  }

  get(): FragmentTreeItem {
    if (!this._tree) {
      throw new Error('Fragment tree not initialized yet!');
    }

    return this._tree;
  }

  init() {
    const classifier = this.components.tools.get(FragmentClassifier);
    const tree = new FragmentTreeItem(this.components, classifier, 'Вся модель');

    this._tree = tree;

    if (this.components.uiEnabled) {
      this.setupUI(tree);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async dispose() {}

  async update(groupSystems: string[]) {
    if (!this._tree) {
      return;
    }

    const classifier = this.components.tools.get(FragmentClassifier);

    if (this._tree.children.length) {
      await this._tree.dispose();
      this._tree = new FragmentTreeItem(this.components, classifier, this._title);
    }

    this._tree.children = this.regenerate(groupSystems);
  }

  private setupUI(tree: FragmentTreeItem) {
    const window = new FloatingWindow(this.components);
    const subTree = tree.uiElement.get('tree');

    window.addChild(subTree);
    window.title = 'Дерево модели';
    this.components.ui.add(window);
    window.visible = false;

    const main = new Button(this.components);

    main.materialIcon = 'account_tree';
    main.tooltip = 'Дерево модели';
    main.onClick.add(() => {
      window.visible = !window.visible;
    });

    this.uiElement.set({main, window});
  }

  private regenerate(groupSystemNames: string[], result = {}) {
    const classifier = this.components.tools.get(FragmentClassifier);
    const systems = classifier.get();
    const groups: FragmentTreeItem[] = [];
    const currentSystemName = groupSystemNames[0]; // storeys
    const systemGroups = systems[currentSystemName];

    if (!currentSystemName || !systemGroups) {
      return groups;
    }

    for (const name in systemGroups) {
      // name is N00, N01, N02...
      // { storeys: "N00" }, { storeys: "N01" }...
      const filter = {...result, [currentSystemName]: [name]};
      const found = classifier.find(filter);
      const hasElements = Object.keys(found).length > 0;

      if (hasElements) {
        const treeItemName = this.getRuClassName(currentSystemName.toLowerCase());
        const treeItem = new FragmentTreeItem(this.components, classifier, `${treeItemName}: ${name}`);

        treeItem.onHovered.add(data => this.onHovered.trigger(data));
        treeItem.onSelected.add(data => this.onSelected.trigger(data));

        treeItem.filter = filter;
        groups.push(treeItem);
        treeItem.children = this.regenerate(groupSystemNames.slice(1), filter);
      }
    }

    return groups;
  }
}
