import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ProjectApiService} from '@projects-api-v2/data-access';
import {ProjectDto} from '@projects-api-v2/util';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {DeleteEntry, DeleteEntryFailure, DeleteEntrySuccess} from './actions';

export interface State {
  projectId: string | null;

  data: ProjectDto | null;
  error: string | null;
  loading: boolean;
}

export const defaultState: State = {
  projectId: null,

  data: null,
  error: null,
  loading: false,
};

@Injectable()
@State<State>({
  name: 'projectEntryView',
  defaults: defaultState,
})
export class ProjectEntryViewState {
  private readonly projectApiService = inject(ProjectApiService);
  private readonly alerts = inject(TuiAlertService);

  @Action(DeleteEntry, {cancelUncompleted: true})
  deleteEntry(ctx: StateContext<State>, {entry}: DeleteEntry) {
    ctx.patchState({loading: true});

    return this.projectApiService.remove(entry.id).pipe(
      switchMap(() => ctx.dispatch(new DeleteEntrySuccess(entry))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new DeleteEntryFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new DeleteEntryFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(DeleteEntrySuccess)
  deleteEntrySuccess(ctx: StateContext<State>, {deletedEntry}: DeleteEntrySuccess) {
    ctx.setState({...defaultState});

    return this.alerts.open(`Проект «${deletedEntry.name}» успешно удалён.`, {
      label: 'Выполнено',
      status: 'success',
    });
  }

  @Action(DeleteEntryFailure)
  deleteEntryFailure(ctx: StateContext<State>, payload: DeleteEntryFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      status: 'error',
    });
  }
}
