/**
 * Интерфейс получаемый из JSON-объекта, хранимого
 * в БД в виде строки для каждого задания. Содержит
 * информации о прилинкованном справочнике
 */
export interface GenerationTaskDataCatalogReferenceDto {
  /**
   * ID прилинкованного справочника
   */
  readonly id: string;
  /**
   * Версия прилинкованного справочника
   */
  readonly version: number;
}
