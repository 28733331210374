import {GenerationTaskDataCatalogReferenceDto} from '../generation-task-data-catalog-reference.dto';
import {GenerationTaskDataHardcodeReferenceDto} from '../generation-task-data-hardcode-reference.dto';
import {HvacConfigDto} from './hvac-config.dto';

export interface HvacDataDto<H extends GenerationTaskDataHardcodeReferenceDto = {}> {
  generationData: HvacConfigDto;
  referenceDataHardcoded?: H | null;
  referenceData?: GenerationTaskDataCatalogReferenceDto | null;
  clientData?: {[key: string]: any};
}
