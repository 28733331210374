import {LayoutCornerSectionLluHallwaySettingsDto} from './layout-corner-section-llu-hallway-settings.dto';
import {LayoutOptionsDto} from './layout-options.dto';
import {LayoutRowedSectionLluHallwaySettingsDto} from './layout-rowed-section-llu-hallway-settings.dto';
import {LayoutTowerSectionLluHallwaySettingsDto} from './layout-tower-section-llu-hallway-settings.dto';
/**
 * @alias RootConfiguration
 */
export type LayoutRootConfigurationDto = {
  layout: LayoutOptionsDto;
  towerSectionSettings: LayoutTowerSectionLluHallwaySettingsDto;
  cornerSectionSettings: LayoutCornerSectionLluHallwaySettingsDto;
  rowedSectionSettings: LayoutRowedSectionLluHallwaySettingsDto;
};
