import {HedgehogWallCreationSystemSettingsDto} from './hedgehog-wall-creation-system-settings.dto';
import {IfcGeometryBuilderSettingsDto} from './ifc-geometry-builder-settings.dto';
import {LluCreationSettingsDto} from './llu-creation-settings.dto';
import {ObjectModelBuilderSettingsDto} from './object-model-builder-settings.dto';
import {ShareWrapWallCreationSystemSettings} from './share-wrap-wall-creation-system-settings.dto';
import {StoreyWallGroupSettings} from './storey-wall-group-settings.dto';

export interface ObjectModelBuilderOptionsDto {
  id?: string;
  objectModelBuilderSettings?: ObjectModelBuilderSettingsDto;
  ifcGeometryBuilderSettings?: IfcGeometryBuilderSettingsDto | null;
  lluCreationSettings?: LluCreationSettingsDto;
  shareWrapWallCreationSystemSettings?: ShareWrapWallCreationSystemSettings;
  hedgehogWallCreationSystemSettings?: HedgehogWallCreationSystemSettingsDto;
  additionalWallCreationSystemSettings?: {
    isolineToWallDistance: number;
  };
  storeyWallGroupSettings?: StoreyWallGroupSettings;
}
