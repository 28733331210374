import {GenerationTaskSubsystemType} from '../../enums';
import {GenerationTaskDataBodySeriallized} from './generation-task-data-body-serialized.dto';

export interface CreateGenerationTaskCreateBodyDto<D = string> {
  data: D extends string ? string : GenerationTaskDataBodySeriallized<D>;
  subsystem: GenerationTaskSubsystemType;
  projectId: string;
  parentId?: string;
  sourceVariantId?: string;
  description?: string;
}
