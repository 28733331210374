export interface FurnitureDto {
  readonly id: string;
  readonly roomReferences: string[];
  readonly type: number;
  readonly geometry: {
    readonly profile: Array<Record<string, any>>; // [{}];
    readonly innerProfiles: Array<Array<Record<string, any>>>; // [[{}]];
    readonly height: number;
  };
  readonly orientation: Record<string, any>;
  readonly vendorCode: string;
}
