import {Router} from '@angular/router';
import {ROUTES} from '@common/consts';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function check404EntityOperator<T>(router: Router): (source$: Observable<any>) => Observable<any> {
  return source$ =>
    source$.pipe(
      catchError((error: unknown) => {
        if ((error as any).status === 404) {
          router.navigate([`${ROUTES.notFound}`], {
            skipLocationChange: true,
          });
        }

        throw error;
      }),
    );
}
