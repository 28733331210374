import {ProjectDto, ProjectPatchBodyDto} from '@projects-api-v2/util';

export class Update {
  static readonly type = '[Project Rotation Form] Update';
  constructor(
    public body: ProjectPatchBodyDto,
    public projectId: string,
  ) {}
}

export class UpdateSuccess {
  static readonly type = '[Project Rotation Form] Update Success';
  constructor(public apiResponse: ProjectDto) {}
}

export class UpdateFailure {
  static readonly type = '[Project Rotation Form] Update Failure';
  constructor(public error: string) {}
}
