import {LayoutPresetCellDto} from './layout-preset-cell.dto';
import {LayoutRectDto} from './layout-rect.dto';

/**
 * @alias LluPresetParameter
 */
export type LayoutLluPresetParameterDto = {
  boundingBox: LayoutRectDto;
  cells: LayoutPresetCellDto;
};
