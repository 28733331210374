import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'textTrim',
  pure: true,
})
export class TextTrimPipe implements PipeTransform {
  transform(value: string): string {
    return value.trim().replaceAll(/  */g, ' ');
  }
}
