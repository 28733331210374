<span tuiMode="onLight" class="plate plate_dark">
  <a
    icon="tuiIconLifeBuoy"
    iconAlign="left"
    tuiLink
    href="https://forms.yandex.ru/cloud/65b1fedef47e73517583ffbb/"
    class="plate__link tui-space_top-3"
  >
    Написать в тех.поддержку
  </a>
</span>
