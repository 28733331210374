import {WallGroupType} from '../../../types';

export interface WallGroupInfoDto {
  wallGroupType: WallGroupType;
  thickness: number;
  length: number;
  longitudinalCoefficient: number;
  description: string;
  isDisabled: boolean;
  thicknessOptions: number[];
  lengthOptions: number[];
}
