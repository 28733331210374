import {GenerationTaskStatusEnum, GenerationTaskSubsystemType} from '../../enums';

export interface GenerationVariantNodeApiDto {
  readonly id: string;
  readonly generationTaskId: string;
  readonly externalId: string;
  readonly status: GenerationTaskStatusEnum;
  readonly subsystem: GenerationTaskSubsystemType;
  readonly errorTitle: string;
  readonly errorMessage: string;
}
