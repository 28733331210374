import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Store} from '@ngxs/store';
import {ProjectBreadcrumbsState} from '@project/data-access/breadcrumbs';
import {TuiSvgModule} from '@taiga-ui/core';
import {TuiLineClampModule} from '@taiga-ui/kit';

@Component({
  standalone: true,
  selector: 'project-breadcrumbs',
  imports: [
    // Angular
    NgFor,
    NgIf,
    AsyncPipe,
    RouterModule,

    // Taiga
    TuiSvgModule,
    TuiLineClampModule,
  ],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectBreadcrumbsComponent {
  private readonly store = inject(Store);

  readonly crumbs$ = this.store.select(ProjectBreadcrumbsState.crumbs);
}
