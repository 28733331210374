import {HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, Subscriber, switchMap, throwError} from 'rxjs';

export function convertBlobError<T>(): (source$: Observable<T>) => Observable<T> {
  return source$ =>
    source$.pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob) {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data

          const fileReader = new FileReader();

          fileReader.readAsText(err.error);

          return new Observable<HttpErrorResponse | ProgressEvent<FileReader>>(
            (observer: Subscriber<HttpErrorResponse | ProgressEvent<FileReader>>): void => {
              fileReader.onload = (ev: ProgressEvent<FileReader>): void => {
                try {
                  const errmsg = JSON.parse((<any>ev.target).result);

                  observer.error(
                    new HttpErrorResponse({
                      error: errmsg,
                      headers: err.headers,
                      status: err.status,
                      statusText: err.statusText,
                      url: err.url || undefined,
                    }),
                  );
                } catch (e) {
                  observer.error(err);
                }

                observer.complete();
              };

              fileReader.onerror = (error: ProgressEvent<FileReader>): void => {
                observer.error(error);
              };
            },
          ).pipe(switchMap(readerError => throwError(() => readerError as Error)));
        }

        return throwError(() => err as Error);
      }),
    );
}
