export interface IfcGeometryBuilderSettingsDto {
  displayArchitectureWalls: boolean;
  displayVirtualWalls: boolean;
  displayArrows: boolean;
  displayFEAGrid: boolean;
  displayFEAGridLines: boolean;
  displayFEAGridCells: boolean;
  displayLoads: boolean;
  defaultTransparency: number;
}
