import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {GenerationSubsystemError} from '@generation-api-v2/util';
import {TextTrimPipe} from '@shared/ui/pipes';
import {TuiAlertService, TuiButtonModule, TuiDialogContext, TuiScrollbarModule} from '@taiga-ui/core';
import {TuiTagModule} from '@taiga-ui/kit';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';

@Component({
  standalone: true,
  imports: [
    // Angular

    // Taiga
    TuiScrollbarModule,
    TuiButtonModule,
    TuiTagModule,

    // Custom
    TextTrimPipe,
  ],
  selector: 'error-preview-dialog.component',
  templateUrl: './error-preview-dialog.component.html',
  styleUrl: './error-preview-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPreviewDialogComponent {
  private readonly context = inject<TuiDialogContext<null, GenerationSubsystemError>>(POLYMORPHEUS_CONTEXT);
  private readonly alerts = inject(TuiAlertService);
  private readonly clipboard = inject(Clipboard);

  readonly error = this.context.data;

  copyToClipboard(stringToCopy: string): void {
    if (stringToCopy && stringToCopy.length > 0) {
      this.clipboard.copy(stringToCopy);
      this.alerts.open('Скопировано', {status: 'success'}).subscribe();
    }
  }
}
