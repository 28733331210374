export function ymInit(initParams = {}) {
  if (<any>window && (<any>window).ym) {
    (<any>window).ym(96202459, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      ...initParams,
    });
  }
}
